import { map, mergeMap, filter } from 'rxjs/operators';

import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { NgbDate, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'lcbsms';
  hasSideBar: boolean = true;
  isHomePage: boolean = false;
  isLoginPage: boolean = false;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly titleService: Title
  ) {
  }

  ngOnInit(): void {
    // for side bar (MAYBE release)
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route: ActivatedRoute) => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route: ActivatedRoute) => route.url)
      )
      .subscribe((event) => {

        var fullUrl = (event as Array<any>).join('/');

        this.isHomePage = !fullUrl || fullUrl.length === 0 || fullUrl === '/';
        this.isLoginPage = fullUrl.indexOf('account/login') === 0;
        const isForgotPasswordPage = fullUrl.indexOf('account/forgot-password') === 0;
        const isResetPasswordPage = fullUrl.indexOf('account/verify-email') === 0;
        const isVerifyEmailPage = fullUrl.indexOf('account/verify-email') === 0;
        this.hasSideBar = !isForgotPasswordPage &&
          !isResetPasswordPage &&
          !isVerifyEmailPage &&
          !this.isLoginPage &&
          !this.isHomePage;
      }
      );

    // for title
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route: ActivatedRoute) => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route: ActivatedRoute) => route.data)
      )
      .subscribe((event) => {
        const title = event && event['title'] ? event['title'] : this.title;
        this.titleService.setTitle(title);
      }
      );
  }
}
