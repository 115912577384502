import { Component, OnInit, OnDestroy, ViewChild, Output, EventEmitter, ElementRef, Inject, HostListener, Directive, Self, QueryList, ViewChildren } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';

import { Observable, Subject, fromEvent } from 'rxjs';
import { map, debounceTime, takeUntil } from 'rxjs/operators';

import { DataTableDirective } from 'angular-datatables';
import { NotifierService } from 'angular-notifier';

import {
  MessagesService, TopMessagesData, MessageItem, TargetStatus, FetchMessagesData, FetchMessagesRequest, SendMessageRequest, SendMessageResponseData,
  ContactsService, ContactsResponseData, LastCheckResponseData,
  CustomerGroupDto, GroupsService, CustomerGroupsData,
  ServicePriceService, CountryServicePrice, ServicePrice, ServicePriceBase,
  CountriesService, CountryListData,
  CustomerService,
  LastCheckDto,
} from '../../_services';
import { ApiResponseBase, ApiResponseAny } from '../../_services/_shared';
import { Address, ContactInfo, Country, PersonContactDto } from '../../_models/index';

import { JwtManagerService } from '../../_helpers/jwt.manager';
import * as signalR from '@aspnet/signalr';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { CustomerProfile } from './../../_services/customer.service';

String.prototype.trim = function () {
  return this.replace(/^\s+|\s+$/g, '');
};

@Component({
  selector: 'customer-messages-center-component',
  templateUrl: './center.component.html',
  styleUrls: ['./center.component.css']
})
export class MessagesCenterComponent implements OnInit {
  @ViewChild("cdkVsv1") contactsCdkVsv: CdkVirtualScrollViewport;
  @ViewChild("cdkVsv2") groupsCdkVsv: CdkVirtualScrollViewport;

  @ViewChild('audioOption') audioPlayerRef: ElementRef;
  onAudioPlay() {
    this.audioPlayerRef.nativeElement.pause();
    this.audioPlayerRef.nativeElement.currentTime = 0;
    this.audioPlayerRef.nativeElement.play();
  }

  loadingLastChecks: boolean = false;
  lastChecks: LastCheckResponseData;
  lastCheckUpdateInterval: any;
  messageCenterInitDt: Date = new Date();

  isMessageChecked(m: MessageItem) {
    if (!m) {
      return false;
    }

    if (this.loadingLastChecks || !this.authorizedNumber) {
      return false;
    }

    if (!m.id || m.id.length < 1) {
      return true;
    }

    let target = MessageViewer.getChannel(this.authorizedNumber, m);
    if (this.selectedViewer && this.selectedViewer.channel == target) {
      this.updateLastCheck(this.selectedViewer.channel);
      return true;
    }

    //--by default mark as read
    if (!this.lastChecks.lastChecks || !this.lastChecks.count) {
      return new Date(this.messageCenterInitDt) >= new Date(m.dt);
    }

    let lastCheck = this.lastChecks.lastChecks.find((c) => c.target == target);

    if (!lastCheck) {
      return new Date(this.messageCenterInitDt) >= new Date(m.dt);
    }

    return new Date(lastCheck.lastCheckDt) >= new Date(m.dt);
  }

  loadingTopMessages: boolean = false;
  topMessages: TopMessagesData;
  viewers: Array<MessageViewer>;
  selectedViewer: MessageViewer;
  loadingContacts: boolean;
  displayDestList: boolean = false;
  contacts: ContactsResponseData;
  filteredContacts: Array<PersonContactDto>;
  filterContacts(filter) {
    if (this.contactsCdkVsv) {
      this.contactsCdkVsv.scrollTo({ top: 0 });
    }
    if (!this.contacts || !this.contacts.contacts) {
      this.filteredContacts = [];
      return;
    }

    if (!filter) {
      this.filteredContacts = this.contacts.contacts;
    } else {
      this.filteredContacts = [];
      filter = filter.toUpperCase();
      this.filteredContacts = this.contacts.contacts.filter((item) => {
        return (item.cell1 && item.cell1.indexOf(filter) > -1) ||
          (item.name && item.name.toUpperCase().indexOf(filter) > -1) ||
          (item.lastName && item.lastName.toUpperCase().indexOf(filter) > -1) ||
          (this.contactDisplayName(item).toUpperCase().indexOf(filter) > -1);
      });
    }
  }

  customerProfile: CustomerProfile;
  authorizedNumber: string;
  loadingGroups: boolean;
  groups: CustomerGroupsData;
  filteredGroups: Array<CustomerGroupDto>;
  filterGroups(filter) {
    if (this.groupsCdkVsv) {
      this.groupsCdkVsv.scrollTo({ top: 0 });
    }
    if (!this.groups || !this.groups.groups) {
      this.filteredGroups = [];
      return;
    }

    if (!filter) {
      this.filteredGroups = this.groups.groups;
    } else {
      this.filteredGroups = [];
      filter = filter.toUpperCase();
      this.filteredGroups = this.groups.groups.filter((item) => {
        return (item.name && item.name.toUpperCase().indexOf(filter) > -1);
      });
    }
  }

  isValidImageURL(url: string) {
    // console.log("CHECK", url);
    return !!url.match(/\w+\.(jpg|jpeg|gif|png|tiff|bmp)$/gi);
  }

  filterImages(urlArray: string[]) {
    // console.log("IMG", urlArray);
    return urlArray.filter(url => this.isValidImageURL(url));
  }

  filterNoImages(urlArray: string[]) {
    // console.log("NO IMG", urlArray);
    return urlArray.filter(url => !this.isValidImageURL(url));
  }

  globalFilter(filter) {
    this.filterContacts(filter);
    this.filterGroups(filter);
  }

  groupMembers: any = {};
  contactsListShown: boolean = true;
  groupsListShown: boolean = true;

  private disposed: boolean;

  private hubConnection: signalR.HubConnection;

  constructor(
    @Inject('BASE_URL') private readonly baseUrl: string,
    private readonly customerService: CustomerService,
    private readonly countriesService: CountriesService,
    private readonly groupsService: GroupsService,
    private readonly contactService: ContactsService,
    private readonly messagesService: MessagesService,
    private readonly jwtManagerService: JwtManagerService,
    private readonly servicePriceService: ServicePriceService,
    private readonly router: Router,
    private readonly location: Location,
    private readonly notifier: NotifierService
  ) {
    this.viewers = new Array<MessageViewer>();
  }

  ngOnInit(): void {
    const self = this;
    this.disposed = false;
    this.loadingTopMessages = true;
    this.loadingLastChecks = true;

    this.lastCheckUpdateInterval = setInterval(() => {
      if (this.selectedViewer) {
        this.updateLastCheck(this.selectedViewer.channel, true);
      }
    }, 3000);

    this.customerService.getProfile()
      .subscribe(
        res => {
          this.authorizedNumber = res.authorizedNumber;
          if (!this.authorizedNumber) {
            this.notifier.notify('error', 'Account not authorized, please contact the admin.');
            this.location.back();
            return;
          }

          this.customerProfile = res;
        },
        error => (err) => {
          this.notifier.notify('error', 'Unable to retrieve profile data. Try again later.');
          this.customerProfile = null;
          this.location.back();
        }
      );

    this.messagesService.getTops()
      .subscribe(
        res => {
          this.topMessages = res;
          this.loadingTopMessages = false;
        },
        error => (err) => {
          // console.log('getTops error', err);

          this.topMessages = null;
          this.loadingTopMessages = false;
        }
      );


    this.messagesService.getLastChecks()
      .subscribe(
        res => {
          this.lastChecks = res;
          this.loadingLastChecks = false;
        },
        error => (err) => {
          this.lastChecks = { count: 0 };
          this.loadingLastChecks = false;
        }
      );

    this.loadingContacts = true;
    this.contactService.getMeUserContacts()
      .subscribe(
        res => {
          this.contacts = res;
          this.filterContacts(null);
          this.loadingContacts = false;
        },
        error => (err) => {
          // console.log('getMeUserContacts error', err);

          this.contacts = null;
          this.filterContacts(null);
          this.loadingContacts = false;
        }
      );

    this.loadingGroups = true;

    this.groupsService.getMeGroups()
      .subscribe(
        res => {
          this.groups = res;
          this.filterGroups(null);
          this.loadingGroups = false;
        },
        error => (err) => {
          // console.log('getMeGroups error', err);

          this.groups = null;
          this.filterGroups(null);
          this.loadingGroups = false;
        }
      );

    this.initializeHub();
  }

  initializeHub() {
    var self = this;

    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${this.baseUrl}/hubs/customer`,
        {
          accessTokenFactory: () => {
            return self.jwtManagerService.getToken();
          }
        })
      .build();

    // listen function
    this.hubConnection.on('inboundMessage',
      (message) => {
        // update/insert top message
        var mInbound = message as MessageItem;
        this.updateOrInsertTopMessage(mInbound);

        // update viewer(if open)
        if (this.viewers) {
          for (let i = 0; i < this.viewers.length; i++) {
            const v = this.viewers[i];
            if (this.messagesCompareByChannel(v.topMessage, mInbound)) {
              v.setTopMessage(mInbound as ViewerMessageItem);
              break;
            }
          }
        }
        this.onAudioPlay();
      }
    );

    //this.hubConnection.on('outboundMessage',
    //  (message) => {
    //    console.log('outbound message', message);
    //  }
    //);

    // configure reconnect
    this.hubConnection.onclose(async () => {
      //await start();
    });

    async function start() {
      if (self.disposed) {
        return;
      }
      try {
        await self.hubConnection.start();
        // console.log('signalr connected');
      } catch (err) {
        // console.log(' signalr connection error', err);
        setTimeout(() => start(), 3000);
      }
    }

    start();

    //this.hubConnection
    //  .start()
    //  .then(() => console.log('Connection started'))
    //  .catch(err => console.log('Error while starting connection: ' + err));
  }

  ngOnDestroy(): void {
    if (this.disposed) {
      return;
    }
    this.disposed = true;

    if (this.hubConnection) {
      this.hubConnection.stop()
        .then(() => {
          // console.log('Connection stopped');
        })
        .catch(err => console.log('Error while stopping connection: ' + err));
    }

    if (this.lastCheckUpdateInterval) {
      clearInterval(this.lastCheckUpdateInterval);
    }
  }

  isReady() {
    return !this.loadingContacts && !this.loadingGroups && !this.loadingTopMessages && this.authorizedNumber;
  }

  showDestList(): void {
    this.displayDestList = true;
  }

  hideDestList(): void {
    this.displayDestList = false;
  }

  toggleDestList(): void {
    this.displayDestList = !this.displayDestList;
    if (this.displayDestList) {
      this.filterContacts(null);
    }
  }

  isSelectedMessage(message) {
    const msg = message as MessageItem;
    if (!msg) {
      return false;
    }

    if (!this.selectedViewer || !this.selectedViewer.topMessage) {
      return false;
    }

    return this.messagesCompareByChannel(msg, this.selectedViewer.topMessage);
  }

  getGroupName(id): string {
    const item = this.findGroupById(id);

    if (item) {
      return item.name || item.id;
    }

    return null;
  }

  findGroupById(id): CustomerGroupDto {
    for (let i = 0; i < this.groups.count; i++) {
      const group = this.groups.groups[i];
      if (group.id === id) {
        return group;
      }
    }
    return null;
  }

  getContactName(number): string {
    const contact = this.findContactByNumber(number);

    if (!contact) {
      return null;
    }

    return this.contactDisplayName(contact);
  }

  getToFormatted(to): string {
    const toArray = to as Array<TargetStatus>;
    if (!to) {
      return null;
    }
    return toArray.map(x => x.number).join(', ');
  }

  findContactIndexByNumber(number): number {
    for (let i = 0; i < this.contacts.count; i++) {
      const contact = this.contacts.contacts[i];
      if (contact.cell1 === number) {
        return i;
      }
    }
    return -1;
  }

  findContactByNumber(number): PersonContactDto {
    const index = this.findContactIndexByNumber(number);
    return index === -1 ? null : this.contacts.contacts[index];
  }

  addMessage(message: MessageItem): number {
    if (!this.topMessages.messages) {
      this.topMessages.messages = [];
      this.topMessages.count = 0;
    }
    this.topMessages.messages.unshift(message);
    this.topMessages.count += 1;
    return 0; // return item index
  }

  findTopMessageByNumber(number): [MessageItem, number] {
    if (this.topMessages && this.topMessages.messages) {
      for (let i = 0; i < this.topMessages.messages.length; i++) {
        const m: MessageItem = this.topMessages.messages[i];
        if (!this.messageIsGroup(m)) {
          if (m.from === number || (m.to && m.to.length === 1 && m.to[0].number === number)) {
            return [m, i];
          }
        }
      }
    }
    return null;
  }

  newMessageToNumber(number): boolean {
    if (!this.validatePhoneNumber(number)) {
      return false;
    }
    let index = this.findContactIndexByNumber(number);
    if (index !== -1) {
      const contact = this.contacts.contacts[index];
      this.newMessageToContact(contact.id);
      return true;
    }

    this.displayDestList = false;

    // find top message by number
    const topMessageResult = this.findTopMessageByNumber(number);
    if (topMessageResult) {
      this.onSelectMessage(topMessageResult[0], topMessageResult[1]);
      return true;
    }

    const message = {
      id: '',
      from: this.authorizedNumber,
      to: [{ number: number }]
    } as MessageItem;
    index = this.addMessage(message);

    // console.log("adding new message", message, index);
    this.onSelectMessage(message, index);
    return true;
  }

  contactDisplayName(p: PersonContactDto, printCell?: boolean): string {
    if (!p) {
      return null;
    }
    const hasName = p.name && p.name.length;
    const hasLastName = p.lastName && p.lastName.length;
    const hasCell = printCell && p.cell1 && p.cell1.length;

    //--name and last name
    if (hasName && hasLastName) {
      if (hasCell) {
        return `${p.name} ${p.lastName} (${p.cell1})`;
      }
      return `${p.name} ${p.lastName}`;
    }

    //--name only
    if (hasName) {
      if (hasCell) {
        return `${p.name} (${p.cell1})`;
      }
      return `${p.name}`;
    }

    //--last name only
    if (hasCell) {
      return `${p.lastName} (${p.cell1})`;
    }
    return `${p.lastName}`;
  }

  newMessageToContact(contactId: string): void {
    if (!contactId || !contactId.length) {
      return;
    }
    const c: PersonContactDto = this.contacts.contacts.find(c => c.id == contactId);
    if (!c) {
      return;
    }

    this.displayDestList = false;

    const topMessageResult = this.findTopMessageByNumber(c.cell1);
    if (topMessageResult) {
      this.onSelectMessage(topMessageResult[0], topMessageResult[1]);
      return;
    }

    const message = {
      id: '',
      from: this.authorizedNumber,
      to: [{ number: c.cell1 }]
    } as MessageItem;
    const index = this.addMessage(message);
    this.onSelectMessage(message, index);
  }

  newMessageToGroup(groupId: string): void {
    if (!groupId || !groupId.length) {
      return;
    }

    const g: CustomerGroupDto = this.groups.groups.find(g => g.id == groupId);
    if (!g) {
      return;
    }

    this.displayDestList = false;

    if (this.topMessages && this.topMessages.messages) {
      for (let i = 0; i < this.topMessages.messages.length; i++) {
        const m: MessageItem = this.topMessages.messages[i];

        if (this.messageIsGroup(m) && m.group === g.id) {
          this.onSelectMessage(m, i);
          return;
        }
      }
    }

    const message = {
      group: g.id,
      from: this.authorizedNumber,
      to: [] //TODO: --group's contacts //no need because targets are taken from group name
    } as MessageItem;

    const index = this.addMessage(message);
    this.onSelectMessage(message, index);
  }

  onSelectMessage(message: any, index): void {
    const m: MessageItem = this.topMessages.messages[index];
    if (!m) {
      this.selectedViewer = null;
      return;
    }

    let viewer: MessageViewer = null;
    for (let i = 0; i < this.viewers.length; i++) {
      const v = this.viewers[i];
      if (this.messagesCompareByChannel(m, v.topMessage)) {
        viewer = v;
        break;
      }
    }

    if (!viewer) {
      viewer = new MessageViewer(this.customerProfile, m, this.messagesService, this.notifier);

      viewer.sent.subscribe(
        message => {
          // update last message
          var mSent = message as MessageItem;
          this.updateTopMessage(mSent);
        }
      );

      // if not group message check if MMS is enabled
      if (!this.messageIsGroup(m) && m.to && m.to.length > 0) {
        const otherPartNumber = m.from === this.authorizedNumber ? m.to[0].number : m.from;

        this.servicePriceService.getMmsEnabledToNumberForCustomer(otherPartNumber)
          .subscribe(
            mmsEnabled => {
              viewer.mmsEnabled = mmsEnabled;
            },
            error => {
              // console.log("[GET MmsEnabled error]", error);
            }
          );
      } else {
        viewer.mmsEnabled = true;
      }

      this.viewers.push(viewer);
    }

    if (viewer.nextMessageNumber === 0) {
      viewer.loadMore();
    }

    if (this.selectedViewer && this.selectedViewer != viewer) {
      this.updateLastCheck(this.selectedViewer.channel, true);
    }

    this.selectedViewer = viewer;

    //update the last check (last view)
    this.updateLastCheck(viewer.channel, true);
  }

  updateLastCheck(target: string, syncServer?: boolean) {
    if (syncServer) {
      this.messagesService.setLastCheck(target).toPromise();
    }

    let check: LastCheckDto = null;

    if (this.lastChecks && this.lastChecks.count) {
      check = this.lastChecks.lastChecks.find(c => c.target == target);
    }

    if (!check) {
      check = {
        target: target,
        lastCheckDt: new Date()
      };
      if (!this.lastChecks.lastChecks) {
        this.lastChecks.lastChecks = [];
      }
      this.lastChecks.lastChecks.push(check);
      this.lastChecks.count = this.lastChecks.lastChecks.length;
    } else {
      check.lastCheckDt = new Date();
    }
  }

  updateOrInsertTopMessage(message: MessageItem): void {
    const update = this.updateTopMessage(message);
    if (update) {
      return;
    }

    if (!this.topMessages) {
      this.topMessages = TopMessagesData.parse({
        count: 0,
        messages: []
      });
    }

    this.topMessages.messages.unshift(message);
    this.topMessages.count += 1;
  }

  updateTopMessage(message: MessageItem): boolean {
    if (!message || !this.topMessages || !this.topMessages.messages)
      return false;

    for (let i = 0; i < this.topMessages.messages.length; i++) {
      const m: MessageItem = this.topMessages.messages[i];
      if (this.messagesCompareByChannel(m, message)) {
        if (i === 0) {
          this.topMessages.messages[i] = message;
        } else {
          this.topMessages.messages.splice(i, 1);
          this.topMessages.messages.unshift(message);
        }
        return true;
      }
    }
    return false;
  }

  openMediaUrl(url): void {
    window.open(url, '_blank');
  }

  onScroll(event: Event) {
    const elem = (event.target as HTMLElement);
    const scrollTop = elem.scrollTop;
    if (scrollTop < 20) {
      const v = this.selectedViewer;
      if (v && v.hasMoreData && !v.loadingMore) {
        this.selectedViewer.loadMore();
      }
    }
  }

  // messages helper functions
  dateToTime(d): string {
    const dt = d as Date;
    if (!dt) {
      return '';
    }

    return formatDate(dt, 'hh:mm a', 'en-us');
  }

  messageFormatDate(d): string {
    const dt = d as Date;
    if (!dt) {
      return '';
    }

    const todayDt = new Date();
    if (formatDate(todayDt, 'yyyy-MM-dd', 'en-us') === formatDate(dt, 'yyyy-MM-dd', 'en-us')) {
      return formatDate(dt, 'hh:mm a', 'en-us');
    }

    return formatDate(dt, 'MMM dd, hh:mm a', 'en-us');
  }

  private static validatePhoneNumberReg = new RegExp('[0-9]+', 'g');

  validatePhoneNumber(n: string): boolean {
    return MessagesCenterComponent.validatePhoneNumberReg.test(n || '');
  }

  messageFirstTo(m: MessageItem): string {
    if (m.to && m.to.length > 0) {
      return m.to[0].number;
    }
    return null;
  }

  messageIsGroup(m: MessageItem): boolean {
    return m.group && m.group.length > 0;
  }

  messageIsExternal(m: MessageItem) {
    return m.from !== this.authorizedNumber;
  }

  messageHasMedia(m: MessageItem): boolean {
    return m.media && m.media.length > 0;
  }

  messageGetOtherPartNumber(m: MessageItem): string {
    if (this.messageIsGroup(m)) {
      return m.group;
    }

    return m.from === this.authorizedNumber ? m.to[0].number : m.from;
  }

  messageGetOtherPartDisplayName(message) {
    const m = message as MessageItem;
    //console.log(JSON.stringify(m));
    if (this.messageIsGroup(m)) {
      return this.getGroupName(m.group);
    }

    const sender = this.messageGetOtherPartNumber(m);
    return this.getContactName(sender) || sender;
  }

  messageGetOtherPartDisplayNumber(message) {
    const m = message as MessageItem;
    if (this.messageIsGroup(m)) {
      return this.getToFormatted(m.to);
    }
    return this.messageGetOtherPartNumber(m);
  }

  messageBuildChannelName(from: string, to: string): string {
    return `|${from}|${to}|`;
  }

  messagesCompareByChannel(m1: MessageItem, m2: MessageItem): boolean {
    if (this.messageIsGroup(m1) || this.messageIsGroup(m2)) {
      return m1.group === m2.group;
    }

    const chOne1 = this.messageBuildChannelName(m1.from, m1.to[0].number);
    const chTwo1 = this.messageBuildChannelName(m2.from, m2.to[0].number);
    const chTwo2 = this.messageBuildChannelName(m2.to[0].number, m2.from);

    if (chOne1 === chTwo1 || chOne1 === chTwo2) {
      return true;
    }

    return false;
  }

  // group members
  getGroupMembers(groupId) {
    if (!groupId) {
      return null;
    }

    if (!this.groupMembers.hasOwnProperty(groupId)) {
      this.groupMembers[groupId] = null;

      this.groupsService.getGroupMembers(groupId)
        .subscribe(
          members => {
            this.groupMembers[groupId] = members;
          },
          error => {
            // console.log("[GET GroupMembers error]", error);
          }
        );
    }

    return this.groupMembers[groupId];
  }

  getContactsNumbers(contacts: any) {
    const arr = contacts as PersonContactDto[];
    if (!arr) {
      return null;
    }

    return arr.map(p => p.name || p.cell1).join(', ');
  }

  statusToTitle(status) {
    if (!status) {
      return 'N/A';
    }

    switch (status.toUpperCase()) {
      case 'SUCCESS':
        return 'Success';
      case 'FAILED':
        return 'Failed';
      case 'DENIED_C':
        return 'Country denied';
      case 'DENIED_C2C':
        return 'Country denied';
    }

    return null;
  }

  hideContactsList() {
    this.contactsListShown = false;
  }

  showContactsList() {
    this.contactsListShown = true;
  }

  hideGroupsList() {
    this.groupsListShown = false;
  }

  showGroupsList() {
    this.groupsListShown = true;
  }

  hasSignature() {
    return this.customerProfile && this.customerProfile.signature && this.customerProfile.signature.length > 0;
  }
}

export class ViewerMessageItem extends MessageItem {
  sending: boolean = false;
  failed: boolean = false;
}

export class MessageViewer {
  authorizedNumber: string;
  customerProfile: CustomerProfile;
  channel: string;
  nextMessageNumber: number = 0;
  topMessage: MessageItem;
  messages: Array<ViewerMessageItem>;
  loadingMore: boolean;
  hasMoreData: boolean;
  mmsEnabled: boolean = false;

  text: string = null;
  files: Array<File> = null;
  @Output()
  sent: EventEmitter<any> = new EventEmitter();

  showMessageInfo: boolean = false;
  messageInfo: ViewerMessageItem = null;

  hasSignature() {
    return this.customerProfile && this.customerProfile.signature && this.customerProfile.signature.length > 0;
  }

  openMessageInfo(m) {
    this.messageInfo = m as ViewerMessageItem;
    this.showMessageInfo = true;
  }

  closeMessageInfo() {
    this.showMessageInfo = false;
    this.messageInfo = null;
  }

  static getChannel(authorizedNumber: string, message: MessageItem): string {
    if (message.group) {
      return `g${message.group}`;
    }
    if (message.from === authorizedNumber) {
      return message.to[0].number;
    }
    return message.from;
  }

  constructor(customerProfile: CustomerProfile,
    topMessage: MessageItem,
    readonly messagesService: MessagesService,
    readonly notifier: NotifierService,
  ) {
    this.customerProfile = customerProfile;
    this.authorizedNumber = customerProfile.authorizedNumber;
    this.topMessage = topMessage;
    this.nextMessageNumber = 0;
    this.messages = new Array<ViewerMessageItem>();
    this.loadingMore = false;
    this.hasMoreData = true;
    this.channel = MessageViewer.getChannel(this.authorizedNumber, topMessage);
  }

  canSendText() {
    if (this.files && this.files.length) {
      return true;
    }
    return this.text && this.text.trim().length > 0;
  }

  setFiles(files: Array<File>) {
    if (!files) {
      this.files = null;
      return;
    }

    let filteredFiles = new Array<File>();
    for (let i = 0; i < files.length; i++) {
      const f = files[i];
      if (f.size <= 2000000) {
        filteredFiles.push(f);
      } else {
        const maxFileLen = 32;
        const fileName = f.name.length <= maxFileLen ? f.name : `${f.name.substr(0, maxFileLen)}...`;
        this.notifier.notify('error', `File '${fileName}' is too large (max 2MB)`);
      }
    }
    this.files = filteredFiles;
  }

  sendMessage() {
    if (!this.canSendText()) {
      return;
    }

    let msgTo = this.topMessage.group
      ? null
      : ([
        {
          number: this.topMessage.from !== this.authorizedNumber ? this.topMessage.from : this.topMessage.to[0].number
        }
      ]
      );

    let messageToSend = {
      id: null,
      from: this.authorizedNumber,
      group: this.topMessage.group,
      to: msgTo,
      text: this.text ? this.text.trim() : null,
      isInbound: false,
      dt: new Date(),
      sending: true,
    } as ViewerMessageItem;

    if (this.hasSignature()) {
      messageToSend.text = messageToSend.text + "\n" + this.customerProfile.signature;
    }

    this.setTopMessage(messageToSend);

    let messageRequest = new SendMessageRequest(messageToSend.text,
      messageToSend.group,
      messageToSend.to ? messageToSend.to.map(x => x.number) : null,
      this.files
    );

    // clean chat
    this.text = '';
    this.setFiles(null);

    this.messagesService.sendMessage(messageRequest)
      .subscribe(
        res => {
          messageToSend.sending = false;

          if (res) {
            // res is SendMessageResponseData
            messageToSend.id = res.messageId;
            messageToSend.media = res.mediaUrls;

            // update status
            if (res.resultResponses) {
              messageToSend.to = res.resultResponses.map(r => {
                return TargetStatus.parse({
                  number: r.to,
                  status: r.status
                });
              }
              );
            }
          } else {
            messageToSend.failed = true;
          }
        },
        error => (err) => {
          // console.log('sendMessage error', err);
          messageToSend.failed = true;
        }
      );
  }

  hasMessages() {
    return this.messages && this.messages.length > 0;
  }

  loadMore() {
    if (!this.hasMoreData) {
      return;
    }

    if (this.loadingMore) {
      return;
    }

    this.loadingMore = true;

    const request = {
      channel: this.channel,
      startNumber: this.nextMessageNumber,
      searchQuery: null
    } as FetchMessagesRequest;

    this.messagesService.fetchMessages(request)
      .subscribe(
        res => {
          this.loadingMore = false;
          this.nextMessageNumber = res.nextStartNumber;
          this.hasMoreData = res.count === res.pageSize && res.nextStartNumber > 0;
          if (res.count > 0) {
            for (let i = 0; i < res.count; i++) {
              this.messages.push(res.messages[i] as ViewerMessageItem);
            }
          }
        },
        error => (err) => {
          // console.log('fetchMessages error', err);

          this.loadingMore = false;
        }
      );
  }

  setTopMessage(m: ViewerMessageItem) {
    if (this.topMessage === m) {
      return;
    }
    this.messages.unshift(m);
    this.topMessage = m;
    this.sent.emit(m);
  }
}

//-credits to: https://stackoverflow.com/a/26502275/2322038
export class Guid {
  static newGuid(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
      var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
}

