import { Router } from '@angular/router';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { NotifierService } from 'angular-notifier';
import { ApiResponseBase, ApiResponseAny, DataTablesResponse, DataTablesResponseAny } from './_shared';
import { PersonContactDto } from '../_models';

@Injectable(({
    providedIn: 'root'
}) as any)
export class ContactsService {
    private readonly apiUrlBase: string;

    constructor(
        @Inject('BASE_URL') private readonly baseUrl: string,
        private readonly http: HttpClient,
        private readonly router: Router,
        private readonly notifier: NotifierService) {
        this.apiUrlBase = `${this.baseUrl}/api/contacts`;
    }

    getContactList(datatablesParameters: any): Observable<DataTablesResponse<PersonContactDto>> {
        return this.http.post<DataTablesResponse<PersonContactDto>>(
            `${this.apiUrlBase}/get_dt`,
            datatablesParameters
        );
    }

    removeContact(contactId: string): Observable<boolean> {
        return this.http.post<ApiResponseAny>(`${this.apiUrlBase}/remove/${contactId}`, {})
            .pipe(map(res => {
                if (ApiResponseAny.isSuccess(res)) {
                    return true;
                }

                this.notifier.notify('error', res.message || 'Delete contact failed');
                return false;
            })
            );
    }

    editContact(contact: PersonContactDto): Observable<boolean> {
        const url = `${this.apiUrlBase}/${contact.id ? 'edit' : 'add'}`;
        return this.http.post<ApiResponseAny>(url, { contact: contact })
            .pipe(map(res => {
                if (ApiResponseAny.isSuccess(res)) {
                    return true;
                }

                this.notifier.notify('error', res.message || 'Save contact failed');
                return false;
            })
            );
    }

    getMeUserContacts(): Observable<ContactsResponseData> {
        return this.getUserContacts('self');
    }

    getUserContacts(userId: string): Observable<ContactsResponseData> {
        return this.http.get<ApiResponseBase<ContactsResponseData>>(`${this.apiUrlBase}/user/${userId}`)
            .pipe(map(res => {
                if (ApiResponseAny.isSuccess(res)) {
                    return res.data;
                }

                this.notifier.notify('error', res.message || 'Unable to retrieve user contacts');
                return null;
            })
            );
    }

    getUserContactsCount(userId: string): Observable<ContactsCountResponseData> {
        return this.http.get<ApiResponseBase<ContactsCountResponseData>>(`${this.apiUrlBase}/user/${userId}/count`)
            .pipe(map(res => {
                if (ApiResponseAny.isSuccess(res)) {
                    return res.data;
                }

                this.notifier.notify('error', res.message || 'Unable to retrieve user contacts count');
                return null;
            })
            );
    }

    importContacts(request: ImportContactsRequest): Observable<ImportContactsData> {
        const formData = new FormData();
        formData.append('file', request.file, request.file.name);
        formData.append('autoGenNames', request.autoGenNames ? 'true' : 'false');

        return this.http.post<ApiResponseBase<ImportContactsData>>(`${this.apiUrlBase}/import`, formData)
            .pipe(map(res => {
                if (ApiResponseAny.isSuccess(res)) {
                    return res.data;
                }

                this.notifier.notify('error', res.message || 'Unable to import the contacts');
                return null;
            })
            );
    }
}

export class ContactsCountResponseData {
    count: number;
}

export class ContactsResponseData {
    count: number;
    contacts: Array<PersonContactDto>;

    static parse(input: any): ContactsResponseData {
        return input as ContactsResponseData;
    }
}

export class ImportContactsRequest {
    constructor(
        public readonly file: File,
        public readonly autoGenNames?: boolean) {
    }
}

export class ImportContactsData {
    imported: number;
    ignored: number;
    invalid: number;
}
