import { Component, OnInit } from '@angular/core';
import { Form, FormBuilder, Validators, FormGroup, FormControl, FormArray } from '@angular/forms';
import { CustomerGroupsData, GroupsService } from './../../../_services/groups.service';
import { ContactsResponseData, ContactsService } from './../../../_services/contacts.service';
import { catchError, take, tap } from 'rxjs/operators';
import { BootstrapOptions } from '@angular/core/src/application_ref';
import { AbstractControl } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { MessagesService, ScheduleMessageRequest } from 'src/app/_services';
import { Select2Data, Select2Option, Select2UpdateEvent } from 'ng-select2-component';
import { Router } from '@angular/router';
import { ifError } from 'assert';

@Component({
  selector: 'app-scheduled-messages-create',
  templateUrl: './scheduled-messages-create.component.html',
  styleUrls: ['./scheduled-messages-create.component.css']
})
export class ScheduledMessagesCreateComponent implements OnInit {
  step: number = 1;
  stepSelectType: number = 1;
  isGroupType: boolean = false;
  stepSelectDestinations: number = 2;
  stepCompose: number = 3;

  form: FormGroup;
  submiting: boolean = false;

  loadingGroups: boolean;
  groups: CustomerGroupsData;
  groups2Options: Select2Option[];

  loadingContacts: boolean;
  contacts: ContactsResponseData;
  contact2Options: Select2Option[];
  toValue: string[] = [];
  limitSelection: number = 1;
  updateToContacts(event: Select2UpdateEvent<string>) {
    this.to.setValue([event.value]);
  }

  constructor(
    private fb: FormBuilder,
    private groupService: GroupsService,
    private contactService: ContactsService,
    private notifier: NotifierService,
    private messagesService: MessagesService,
    private router: Router) {
    this.form = fb.group({
      groupId: [],
      to: [[]],
      scheduledDt: ['', [Validators.required, this.futureDateValidator]],
      text: [],
      files: []
    });
  }

  futureDateValidator(g: FormControl) {
    const val = g.value;
    if (!val) {
      return null;
    }
    var date = new Date(val);
    var now = new Date();
    if (now >= date) {
      return { pastDate: true };
    }
    return null;
  }


  get groupId() {
    return this.form.get('groupId')
  }

  get to() {
    return this.form.get('to')
  }

  get scheduledDt() {
    return this.form.get('scheduledDt');
  }

  get text() {
    return this.form.get('text')
  }

  get files() {
    return this.form.get('files')
  }

  setFiles(files: Array<File>) {
    if (!files) {
      this.files.setValue([]);
      return;
    }

    let filteredFiles = new Array<File>();
    for (let i = 0; i < files.length; i++) {
      const f = files[i];
      if (f.size <= 2000000) {
        filteredFiles.push(f);
      } else {
        const maxFileLen = 32;
        const fileName = f.name.length <= maxFileLen ? f.name : `${f.name.substr(0, maxFileLen)}...`;
        this.notifier.notify('error', `File '${fileName}' is too large (max 2MB)`);
      }
    }
    this.files.setValue(filteredFiles);
  }

  ngOnInit() {
    this.loadingGroups = true;
    this.groupService.getMeGroups()
      .pipe(
        take(1),
        tap(() => this.loadingGroups = false)
      )
      .subscribe(
        res => {
          this.groups = res;
          this.groups2Options = res.groups.map(r => {
            return { label: r.name, value: r.id };
          });
        },
        error => {
          this.groups = { count: 0, groups: null };
          this.groups2Options = [];
        }
      );

    this.loadingContacts = true;
    this.contactService.getMeUserContacts()
      .pipe(
        take(1),
        tap(() => this.loadingContacts = false)
      )
      .subscribe(
        res => {
          this.contacts = res;
          this.contact2Options = res.contacts.map(r => {
            let displayName = r.name;
            if (r.lastName && r.lastName.length) {
              displayName = `${r.name} ${r.lastName}`
            }
            if (r.cell1 && r.cell1.length) {
              displayName = `${displayName} (${r.cell1})`;
            }
            return { label: displayName, value: r.id };
          });
        },
        error => {
          this.contacts = { count: 0, contacts: null };
          this.contact2Options = [];
        }
      );
  }

  restart() {
    this.form.reset({});
    this.isGroupType = false;
    this.step = 1;
  }

  goToSelectDestinations(isGroup: boolean) {
    this.isGroupType = isGroup;
    if (isGroup) {
      this.to.setValidators([]);
      this.groupId.setValidators([Validators.required]);
    } else {
      this.to.setValidators([Validators.required]);
      this.groupId.setValidators([]);
    }
    this.groupId.updateValueAndValidity();
    this.to.updateValueAndValidity();
    this.step = 2;
  }

  goToCompose() {
    this.step = 3;
  }

  submitForm() {
    if (this.submiting)
      return;

    this.submiting = true;

    const request: ScheduleMessageRequest = this.form.value;

    this.messagesService.scheduleMessage(request)
      .pipe(take(1), tap((r) => this.submiting = false))
      .subscribe(id => {
        if (id) {
          this.router.navigate(["/customer/messages/scheduler/list"]);
          this.notifier.notify("success", "The shecule has been completed");
        } else {
          this.notifier.notify("error", "Schedule operation has failed")
        }
      }, error => {
        this.notifier.notify("error", "An error has ocurred");
      })
  }
}
