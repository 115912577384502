import { AfterViewInit, Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { FormsService } from 'src/app/_services';
import { MessagesService, ScheduledMessageDto } from 'src/app/_services/messages.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-scheduled-messages-list',
  templateUrl: './scheduled-messages-list.component.html',
  styleUrls: ['./scheduled-messages-list.component.css']
})
export class ScheduledMessagesListComponent implements OnInit, AfterViewInit {
  @ViewChild(DataTableDirective)
  private datatableElement: DataTableDirective;

  dtOptions: DataTables.Settings = {};
  items: ScheduledMessageDto[] = [];

  removing: boolean = false;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly fb: FormBuilder,
    private readonly formsService: FormsService,
    private readonly messagesService: MessagesService) {
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.refreshTable());
  }

  ngOnInit(): void {
    var self = this;


    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      processing: true,
      responsive: true,
      autoWidth: false,
      searchDelay: 600,
      columns: [
        { data: 'id', visible: false, searchable: false },
        { data: 'scheduledDt' },
        { data: 'target' },
        { data: 'text' },
        { data: 'processedOn' }
      ],
      order: [[1, 'asc'], [4, 'asc']],
      ajax: (dataTablesParameters: any, callback) => {
        self.messagesService.getScheduledMessagesList(dataTablesParameters)
          .subscribe(resp => {
            self.items = resp.data;

            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          }
          );
      }
    };

    // enable selected view
    const selView = this.route.snapshot.paramMap.get('sview');
    if (selView === 'add') {
      this.addItem();
    }
  }

  refreshTable(): void {
    this.datatableElement.dtInstance
      .then(
        (dtInstance: DataTables.Api) => {
          dtInstance.draw(true);
        }
      );
  }

  addItem() {
    this.router.navigate(['customer/messages/scheduler/create']);
  }

  removeItem(item: any, i: number) {
    if (!item) {
      return;
    }

    if (this.removing) {
      return;
    }

    if (!confirm(`Do you want to cancel the scheduled message`)) {
      return;
    }

    this.removing = true;

    this.messagesService.removeScheduled(item.id)
      .subscribe({
        next: res => {
          if (res) {
            this.refreshTable();
          }
          this.removing = false;
        },
        error: err => {
          this.removing = false;
        }
      }
      );
  }
}

