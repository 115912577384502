import { Injectable, Inject, Output, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
} as any)
export class JwtManagerService {
  key: string = 'access_token';
  keyRefreshToken: string = 'refresh_token';

  @Output() tokenChanged : EventEmitter<any> = new EventEmitter();
  constructor() {

  }

  setToken(token: string) {
    const  prevToken = this.getToken();
    if (token !== prevToken) {
      window.localStorage.setItem(this.key, token);
      this.tokenChanged.emit(token);
    }
  }

  getToken(): string {
    const t = window.localStorage.getItem(this.key);
    return t || null;
  }
  
  removeToken(): void {
    window.localStorage.removeItem(this.key);
    this.tokenChanged.emit(null);
  }

  getRefreshToken(): string {
    const t = window.localStorage.getItem(this.keyRefreshToken);
    return t || null;
  }

  setRefreshToken(refreshToken: string) {
    window.localStorage.setItem(this.keyRefreshToken, refreshToken);
  }
}
