import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { CustomerService, BlackListItem } from '../../_services';
import { JwtManagerService } from '../../_helpers';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { formatDate } from '@angular/common';

import { DataTableDirective } from 'angular-datatables';
import { NotifierService } from 'angular-notifier';

declare var $: any;

@Component({
  selector: 'customer-blacklist-component',
  templateUrl: './component.html',
  styles: []
})
export class CustomerBlackListComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  items: BlackListItem[] = [];
  removing: boolean = false;

  @ViewChild(DataTableDirective)
  private datatableElement: DataTableDirective;

  constructor(
    private readonly customerService: CustomerService,
    private readonly jwtManager: JwtManagerService,
    private readonly notifier: NotifierService) {
  }

  ngOnInit(): void {
    var self = this;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      processing: true,
      responsive: true,
      autoWidth: false,
      searchDelay: 250,
      columns: [
        { data: 'name' },
        { data: 'number' },
        { data: 'createdOn' }
      ],
      order: [[0, 'asc']],
      ajax: (dataTablesParameters: any, callback) => {
        self.customerService.getBlackList(dataTablesParameters)
          .subscribe(resp => {
            self.items = resp.data;

            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          }
          );
      }
      //columnDefs: [
      //  {
      //    targets: [3], render: function () {
      //      return `Hi`;
      //    }
      //  }
      //],
      //ajax: {
      //  url: this.customerService.getBlackListUrl(),
      //  contentType: 'application/json',
      //  type: 'POST',
      //  beforeSend: (xhr, settings) => {
      //    xhr.setRequestHeader('Authorization', `Bearer ${this.jwtManager.getToken()}`);
      //  },
      //  data: (d, settings) => {
      //    return JSON.stringify(d);
      //  }
      //}
    };
  }

  ngOnDestroy(): void {

  }

  removeItem(item: any, index: number) {
    if (this.removing) {
      return;
    }

    var displayName = item.name && item.name.length ? item.name : item.number;

    if (!confirm(`Do you want to delete the item: ${displayName}`)) {
      return;
    }


    this.removing = true;

    this.customerService.delBlackListItem(item.number)
      .subscribe({
        next: res => {
          this.removing = false;
          if (res) {
            this.datatableElement.dtInstance
              .then(
                (dtInstance: DataTables.Api) => {
                  dtInstance.draw(false);
                }
              );
          }
        },
        error: err => {
          this.removing = false;
          // notity the error
          // console.log("[ERR on removeItem]", err);
        }
      });
  }

  formatDate(d): string {
    const dt = d as Date;
    if (!dt) {
      return '';
    }

    return formatDate(dt, 'MMM dd, yyyy', 'en-us');
  }

  newNumberToBlacklist: string = null;
  addingToBlacklist: boolean = false;
  openAddToBlackList() {
    this.newNumberToBlacklist = null;
    $('#addToBlacklistModal').modal('show');
  }

  addToBlackList() {
    if (!this.newNumberToBlacklist || !this.newNumberToBlacklist.length) {
      return;
    }

    if (!confirm(`Do you want to block the number: ${this.newNumberToBlacklist}`)) {
      return;
    }

    this.addingToBlacklist = true;
    this.customerService.addBlackListItem(this.newNumberToBlacklist)
      .subscribe({
        next: res => {
          this.addingToBlacklist = false;
          if (res) {
            this.datatableElement.dtInstance
              .then(
                (dtInstance: DataTables.Api) => {
                  dtInstance.draw(false);
                }
              );
            this.notifier.notify('success', `The number ${this.newNumberToBlacklist} has been locked`);
            this.newNumberToBlacklist = null;
          }
        },
        error: err => {
          this.addingToBlacklist = false;
          this.notifier.notify('error', 'An error has occurred');
        }
      });
  }
}

