import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NotifierService } from 'angular-notifier';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResponseBase, ApiResponseAny, DataTablesResponse, DataTablesResponseAny } from "./_shared";
import { Address, ContactInfo, Country } from '../_models/index';

@Injectable(({
  providedIn: 'root'
}) as any)
export class CustomerService {

  constructor(
    @Inject('BASE_URL') private readonly baseUrl: string,
    private readonly http: HttpClient,
    private readonly router: Router,
    private readonly notifier: NotifierService) {

  }

  getProfile(): Observable<CustomerProfile> {
    return this.http.get<GetCustomerProfileResponse>(`${this.baseUrl}/api/customer/profile`)
      .pipe(map(res => {
        if (ApiResponseAny.isSuccess(res)) {
          return res.data;
        }

        const message = res && res.message ? res.message : 'Error retrieving user profile';
        this.notifier.notify('error', message);
        return null;
      })
      );
  }

  updateProfile(model: EditCustomerProfile): Observable<boolean> {
    return this.http.post<ApiResponseAny>(`${this.baseUrl}/api/customer/profile/update`, model)
      .pipe(map(res => {
        if (ApiResponseAny.isSuccess(res)) {
          this.notifier.notify('success', 'Profile updated');
          return true;
        }

        this.notifier.notify('error', res.message || 'Profile update failed');
        return false;
      })
      );
  }

  renewApiToken(): Observable<string> {
    return this.http.get<ApiResponseAny>(`${this.baseUrl}/api/customer/renewapitoken`)
      .pipe(map(res => {
        if (ApiResponseAny.isSuccess(res)) {
          this.notifier.notify('success', 'API token updated');
          return res.data.apiToken;
        }
        this.notifier.notify('error', res.message || 'Fail to renew Api token');
        return null;
      })
      );
  }

  getBlackListUrl(): string {
    return `${this.baseUrl}/api/customer/blacklist`;
  }

  getBlackList(datatablesParameters: any): Observable<DataTablesResponse<BlackListItem>> {
    return this.http.post<DataTablesResponse<BlackListItem>>(this.getBlackListUrl(), datatablesParameters);
  }

  delBlackListItem(number: string): Observable<boolean> {
    return this.http.post<ApiResponseAny>(`${this.baseUrl}/api/customer/blacklist/remove/${number}`, {})
      .pipe(map(res => {
        if (ApiResponseAny.isSuccess(res)) {
          return true;
        }

        this.notifier.notify('error', res.message || 'Delete contact from blacklist failed');
        return false;
      })
      );
  }

  addBlackListItem(number: string): Observable<boolean> {
    return this.http.post<ApiResponseAny>(`${this.baseUrl}/api/customer/blacklist/add/${number}`, {})
      .pipe(map(res => {
        if (ApiResponseAny.isSuccess(res)) {
          return true;
        }

        this.notifier.notify('error', res.message || 'Delete contact from blacklist failed');
        return false;
      })
      );
  }

  resetPassword(model: any): Observable<boolean> {
    return this.http.post<ApiResponseAny>(`${this.baseUrl}/api/customer/reset-password`, model)
      .pipe(map(res => {
        if (ApiResponseAny.isSuccess(res)) {
          this.notifier.notify('success', res.message || 'The password has been changed');
          return true;
        }

        this.notifier.notify('error', res.message || 'Reset password failed');
        return false;
      })
      );
  }

  getListDt(datatablesParameters: any): Observable<DataTablesResponse<CustomerDto>> {
    return this.http.post<DataTablesResponse<CustomerDto>>(
      `${this.baseUrl}/api/customer/list-dt`,
      datatablesParameters
    );
  }

  registerCustomer(model: RegisterCustomerDto): Observable<string> {
    return this.http.post<ApiResponseBase<string>>(`${this.baseUrl}/api/customer/register`, { customer: model })
      .pipe(map(res => {
        if (ApiResponseAny.isSuccess(res)) {
          this.notifier.notify('success', 'Customer registration success');
          return res.data;
        }

        this.notifier.notify('error', res.message || 'Customer registration failed');
        return null;
      })
      );
  }

  updateCustomer(model: CustomerDto): Observable<boolean> {
    return this.http.post<ApiResponseAny>(`${this.baseUrl}/api/customer/update`, { customer: model })
      .pipe(map(res => {
        if (ApiResponseAny.isSuccess(res)) {
          this.notifier.notify('success', 'Customer has been updated');
          return true;
        }

        this.notifier.notify('error', res.message || 'Customer update has failed');
        return false;
      })
      );
  }

  getAuthorizedNumber(): Observable<string> {
    return this.http.get<ApiResponseAny>(`${this.baseUrl}/api/customer/number`)
      .pipe(map(res => {
        if (ApiResponseAny.isSuccess(res)) {
          return res.data.number;
        }

        this.notifier.notify('error', res.message || 'Unable to retrieve authorized number');
        return null;
      })
      );
  }

  getAuthorizedNumberById(customerId: string): Observable<string> {
    return this.http.get<ApiResponseAny>(`${this.baseUrl}/api/customer/${customerId}/number`)
      .pipe(map(res => {
        if (ApiResponseAny.isSuccess(res)) {
          return res.data.number;
        }

        this.notifier.notify('error', res.message || 'Unable to retrieve the customer\'s authorized number');
        return null;
      })
      );
  }

  setAuthorizedNumberById(customerId: string, number: string): Observable<boolean> {
    return this.http.post<ApiResponseAny>(`${this.baseUrl}/api/customer/${customerId}/number/${(number || 'null')}`, {})
      .pipe(map(res => {
        if (ApiResponseAny.isSuccess(res)) {
          return true;
        }

        this.notifier.notify('error', res.message || 'Unable to save the customer\'s authorized number');
        return false;
      })
      );
  }
}

export class GetCustomerProfileResponse extends ApiResponseBase<CustomerProfile> {

}

export class CustomerProfile {
  constructor(public name: string,
    public lastName: string,
    public contactPhone: string,
    public authorizedNumber: string,
    public apiToken: string,
    public webhookUrl: string,
    public webhookHeaderName: string,
    public webhookHeaderValue: string,
    public signature: string) {
  }

  static parse(input: any): CustomerProfile {
    return new CustomerProfile(input.name, input.lastName, input.contactPhone, input.authorizedNumber, input.apiToken,
      input.webhookUrl, input.webhookHeaderName, input.webhookHeaderValue, input.signature);
  }
}

export class EditCustomerProfile {
  constructor(public name: string,
    public lastName: string,
    public contactPhone: string,
    public webhookUrl: string,
    public webhookHeaderName: string,
    public webhookHeaderValue: string,
    public signature: string) {

  }

  static parse(input: any): EditCustomerProfile {
    return new EditCustomerProfile(input.name, input.lastName, input.contactPhone,
      input.webhookUrl, input.webhookHeaderName, input.webhookHeaderValue, input.signature);
  }
}

export class BlackListItem {
  number: string;
  name: string;
  createdOn: Date;
}

export class NewCustomerDto {
  name: string;
  lastName: string;

  username: string;
  email: string;

  address: Address;
  contact: ContactInfo;

  static parse(input: any): NewCustomerDto {
    return input as NewCustomerDto;
  }
}
export class CustomerDto extends NewCustomerDto {
  id: string;

  static parse(input: any): CustomerDto {
    return input as CustomerDto;
  }
}

export class RegisterCustomerDto extends CustomerDto {
  password: string;

  static parse(input: any): RegisterCustomerDto {
    return input as RegisterCustomerDto;
  }
}

