import { Router } from '@angular/router';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { NotifierService } from 'angular-notifier';
import { ApiResponseBase, ApiResponseAny, DataTablesResponse, DataTablesResponseAny } from './_shared';
import { Country } from '../_models';

@Injectable(({
  providedIn: 'root'
}) as any)
export class ServicePriceService {
  private readonly apiUrlBase: string;

  constructor(
    @Inject('BASE_URL') private readonly baseUrl: string,
    private readonly http: HttpClient,
    private readonly notifier: NotifierService,
    private readonly router: Router) {
    this.apiUrlBase = `${this.baseUrl}/api/serviceprice`;
  }

  getListDt(datatablesParameters: any): Observable<DataTablesResponse<ServicePrice>> {
    return this.http.post<DataTablesResponse<ServicePrice>>(
      `${this.apiUrlBase}/list-dt`,
      datatablesParameters
    );
  }

  getPriceForCountry(countryId: string): Observable<ServicePriceBase> {
    return this.http.get<ApiResponseAny>(`${this.apiUrlBase}/country/${countryId}`)
      .pipe(map(res => {
        if (ApiResponseAny.isSuccess(res)) {
          return res.data as ServicePriceBase;
        }

        const message = res && res.message ? res.message : 'Error retrieving service prices for country';
        this.notifier.notify('error', message);
        return null;
      })
      );
  }

  setPriceForCountry(countryId: string, price: ServicePriceBase): Observable<boolean> {
    return this.http.post<ApiResponseAny>(`${this.apiUrlBase}/country/${countryId}`, price)
      .pipe(map(res => {
          if (ApiResponseAny.isSuccess(res)) {
            return true;
          }

          const message = res && res.message ? res.message : 'Error saving service prices for country';
          this.notifier.notify('error', message);
          return false;
        })
      );
  }


  getPriceForCustomer(customerId: string, countryId: string): Observable<ServicePriceBase> {
    return this.http.get<ApiResponseAny>(`${this.apiUrlBase}/customer/${customerId}/country/${countryId}`)
      .pipe(map(res => {
          if (ApiResponseAny.isSuccess(res)) {
            return res.data as ServicePriceBase;
          }

          const message = res && res.message ? res.message : 'Error retrieving service prices for customer';
          this.notifier.notify('error', message);
          return null;
        })
      );
  }

  setPriceForCustomer(customerId: string, countryId: string, price: ServicePriceBase): Observable<boolean> {
    return this.http.post<ApiResponseAny>(`${this.apiUrlBase}/customer/${customerId}/country/${countryId}`, price)
      .pipe(map(res => {
          if (ApiResponseAny.isSuccess(res)) {
            this.notifier.notify('success', 'Service price updated');
            return true;
          }

          const message = res && res.message ? res.message : 'Error saving service prices for customer';
          this.notifier.notify('error', message);
          return false;
        })
      );
  }

  removePriceForCustomer(customerId: string, countryId: string): Observable<boolean> {
    return this.http.delete<ApiResponseAny>(`${this.apiUrlBase}/customer/${customerId}/country/${countryId}`)
      .pipe(map(res => {
          if (ApiResponseAny.isSuccess(res)) {
            this.notifier.notify('success', 'Service price removed');
            return true;
          }

          const message = res && res.message ? res.message : 'Error removing the customer\'s service prices for country';
          this.notifier.notify('error', message);
          return false;
        })
      );
  }
  
  getCountriesPriceForCustomer(customerId: string): Observable<CustomerCountriesServicePrice> {
    return this.http.get<ApiResponseAny>(`${this.apiUrlBase}/customer/${customerId}/countries`)
      .pipe(map(res => {
          if (ApiResponseAny.isSuccess(res)) {
            return res.data as CustomerCountriesServicePrice;
          }

          const message = res && res.message ? res.message : 'Error retrieving countries services price for customer';
          this.notifier.notify('error', message);
          return null;
        })
      );
  }

  getCountriesPriceForCurrentCustomer(): Observable<CustomerCountriesServicePrice> {
    return this.http.get<ApiResponseAny>(`${this.apiUrlBase}/customer/countries`)
      .pipe(map(res => {
          if (ApiResponseAny.isSuccess(res)) {
            return res.data as CustomerCountriesServicePrice;
          }

          const message = res && res.message ? res.message : 'Error retrieving countries services price';
          this.notifier.notify('error', message);
          return null;
        })
      );
  }

  getMmsEnabledToNumberForCustomer(number: string): Observable<boolean | null> {
    return this.http.get<ApiResponseAny>(`${this.apiUrlBase}/mms-enabled/number/${number}`)
      .pipe(map(res => {
          if (ApiResponseAny.isSuccess(res)) {
            return res.data as boolean;
          }

          return false;
        })
      );
  }

}

export class CustomerCountriesServicePrice {
  customerId: string;
  count: number;
  servicesPrice: Array<CountryServicePrice>;

  static parse(input: any): CustomerCountriesServicePrice {
    return input as CustomerCountriesServicePrice;
  }
}

export class ServicePriceBase {
  mmsEnabled: boolean;
  inboundMmsPrice: number;
  inboundSmsPrice: number;
  outboundMmsPrice: number;
  outboundSmsPrice: number;

  static parse(input: any): ServicePriceBase {
    return input as ServicePriceBase;
  }
}

export class CountryServicePrice extends ServicePriceBase {
  countryId: string;

  static parse(input: any): CountryServicePrice {
    return input as CountryServicePrice;
  }
}

export class CustomerServicePrice extends CountryServicePrice {
  customerId: string;

  static parse(input: any): CustomerServicePrice {
    return input as CustomerServicePrice;
  }
}

export class ServicePrice extends CountryServicePrice {
  id: string;

  static parse(input: any): ServicePrice {
    return input as ServicePrice;
  }
}
