import { Component, OnInit } from '@angular/core';
import { JwtHelperService } from '../_helpers/jwt.helperservice';
import { JwtManagerService } from '../_helpers/jwt.manager';
import { Router } from "@angular/router";

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit {
  isExpanded = false;

  roles: Array<string> = null;
  isAdmin = false;
  isCustomer = false;
  isAuthenticated = false;

  constructor(private readonly jwtManager: JwtManagerService, private readonly jwtHelper: JwtHelperService, private readonly router: Router) {
    this.refreshNavMenu();
  }

  resetFields() {
    this.roles = null;
    this.isAuthenticated = this.isAdmin = this.isCustomer = false;
  }

  ngOnInit() {
    this.jwtManager.tokenChanged.subscribe(
      token => {
        this.refreshNavMenu();
      }
    );
  }

  private refreshNavMenu() {
    this.resetFields();

    this.isAuthenticated = this.jwtHelper.isAuthenticated();
    if (!this.isAuthenticated) {
      return;
    }

    try {
      const tokenPayload = this.jwtHelper.decodeToken(this.jwtManager.getToken());
      this.roles = tokenPayload.role; // array of string
      this.isAdmin = this.roles && this.roles.indexOf('Admin') >= 0;
      this.isCustomer = this.roles && this.roles.indexOf('Customer') >= 0;
    } catch (e) {
      this.roles = null;
      this.isAdmin = this.isCustomer = false;
      // console.log(`error decoding token: ${JSON.stringify(e)}`);
    }
  }


  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  logout() {
    this.jwtHelper.removeToken();
    this.router.navigate(['/account/login']);
  }
}
