import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';

import {
  ServicePriceService,
  CustomerCountriesServicePrice, ServicePriceBase, CountryServicePrice,

  CountriesService
} from '../../../_services';
import { Country } from '../../../_models/index';
@Component({
  selector: 'customer-services-prices',
  templateUrl: './component.html',
  styleUrls: ['./component.css']
})
export class CustomerServicesPrices implements OnInit {
  loadingCountries: boolean = false;
  countries: Country[] = null;
  loadingConfiguredSp: boolean = false;
  configuredSp: CustomerCountriesServicePrice = null;


  constructor(
    private readonly router: Router,
    private readonly servicePriceService: ServicePriceService,
    private readonly countriesService: CountriesService) {
  }


  ngOnInit(): void {
    const self = this;
    this.loadCountries();
    this.loadConfiguredSp();
  }

  loadCountries() {
    if (this.loadingCountries) {
      return;
    }
    this.loadingCountries = true;

    this.countriesService.getList()
      .subscribe(
        res => {
          this.countries = res != null && res.countries != null ? res.countries : null;
          this.loadingCountries = false;
        },
        error => (err) => {
          // console.log('[GET countries] error', err);
          this.countries = null;
          this.loadingCountries = false;
        }
      );
  }

  getCountryIso2ById(id: string): string {
    if (this.loadingCountries) {
      return null;
    }

    if (!this.countries) {
      return id;
    }

    let countryName = null;
    this.countries.some(c => {
      if (c.id === id) {
        countryName = c.isoCode;
        return true;
      }
      return false;
    });
    return countryName;
  }

  loadConfiguredSp() {
    if (this.loadingConfiguredSp) {
      this.loadingConfiguredSp = null;
    }
    this.loadingConfiguredSp = true;
    this.servicePriceService.getCountriesPriceForCurrentCustomer()
      .subscribe(
        res => {
          this.configuredSp = res;
          this.loadingConfiguredSp = false;
        },
        error => (err) => {
          // console.log('[GET configuredSp] error', err);
          this.configuredSp = null;
          this.loadingConfiguredSp = false;
        }
      );
  }

}
