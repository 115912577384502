import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomerService, CustomerProfile, EditCustomerProfile } from '../../_services';
import { ClipboardHelper } from '../../_helpers';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'customer-profile-component',
  templateUrl: './component.html',
  styleUrls: []
})
export class CustomerProfileComponent implements OnInit {
  theForm: FormGroup;
  submitting: boolean = false;
  loading: boolean = false;
  profile: CustomerProfile = null;
  lastUpdateFailed: number = null;
  displayEditForm: boolean = false;
  regeneratingApiToken: boolean = false;

  constructor(
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly customerService: CustomerService,
    private readonly notifier: NotifierService) {
  }

  ngOnInit(): void {
    this.loading = true;

    this.theForm = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      lastName: ['', [Validators.required]],
      contactPhone: ['', [Validators.pattern('[0-9]+')]],
      signature: ['', []],
      webhookUrl: [''],
      webhookHeaderName: [''],
      webhookHeaderValue: [''],
    });

    this.customerService.getProfile()
      .subscribe({
        next: res => {
          this.loading = false;
          this.profile = res;

          if (!res) {
            this.router.navigate(['/']);
          }

          // update form
          this.theForm.get('name').setValue(res.name);
          this.theForm.get('lastName').setValue(res.lastName);
          this.theForm.get('contactPhone').setValue(res.contactPhone);
          this.theForm.get('signature').setValue(res.signature);
          this.theForm.get('webhookUrl').setValue(res.webhookUrl);
          this.theForm.get('webhookHeaderName').setValue(res.webhookHeaderName);
          this.theForm.get('webhookHeaderValue').setValue(res.webhookHeaderValue);
        },
        error: err => {
          this.loading = false;
          // notity the error
          // console.log("getProfile error", err);
          this.router.navigate(['/']);
        }
      }
      );

    // enable selected view
    const selView = this.route.snapshot.paramMap.get('sview');
    if (selView === 'edit') {
      this.displayEditForm = true;
    }
  }

  theLastUpdateFailed(): boolean {
    return this.lastUpdateFailed === 1;
  }

  onSubmit() {
    if (this.submitting) {
      return;
    }

    if (this.theForm.invalid) {
      return;
    }

    this.lastUpdateFailed = null;
    this.submitting = true;

    const model = EditCustomerProfile.parse(this.theForm.value);

    this.customerService.updateProfile(model)
      .subscribe({
        next: res => {
          this.submitting = false;
          if (!res) {
            this.lastUpdateFailed = 1;
          }
          this.profile.name = model.name;
          this.profile.lastName = model.lastName;
          this.profile.contactPhone = model.contactPhone;
          this.profile.webhookUrl = model.webhookUrl;
          this.profile.webhookHeaderName = model.webhookHeaderName;
          this.profile.webhookHeaderValue = model.webhookHeaderValue;
          this.profile.signature = model.signature;
          this.toggleEditForm();
        },
        error: err => {
          this.submitting = false;
          this.lastUpdateFailed = 1;
        }
      }
      );
  }

  copyApiToken() {
    if (!this.profile.apiToken) {
      return;
    }
    ClipboardHelper.copyToClipboard(this.profile.apiToken);
    this.notifier.notify('success', 'Token copied to clipboard');
  }

  toggleEditForm() {
    this.displayEditForm = !this.displayEditForm;
    return false;
  }

  regenerateApiToken() {
    if (this.regeneratingApiToken) {
      return;
    }
    this.regeneratingApiToken = true;

    this.customerService.renewApiToken()
      .subscribe({
        next: res => {
          this.regeneratingApiToken = false;
          if (res) {
            this.profile.apiToken = res;
          }
        },
        error: err => {
          this.regeneratingApiToken = false;
          // console.log("[ERR regenerateApiToken]", err);
        }
      }
      );
  }
}
