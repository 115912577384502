import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { CanActivate, Router, ActivatedRoute } from "@angular/router";
import { UserService, ResetPasswordModel } from '../../_services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'reset-password-component',
  templateUrl: './component.html',
  styleUrls: []
})
export class ResetPasswordComponent implements OnInit {
  theForm: FormGroup;
  submitting: boolean = false;

  constructor(
    readonly fb: FormBuilder,
    readonly router: Router,
    readonly userService: UserService,
    readonly activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    const resetToken = this.activatedRoute.snapshot.queryParamMap.get('token') || '';
    this.theForm = this.fb.group({
      token: [resetToken, [Validators.required]],
      newPassword: ['', [Validators.required, Validators.minLength(4)]],
    });
  }

  onSubmit() {
    if (this.submitting) {
      return;
    }

    if (this.theForm.invalid) {
      return;
    }

    this.submitting = true;

    const model = new ResetPasswordModel(
      this.theForm.get('token').value,
      this.theForm.get('newPassword').value,
    );

    this.userService.resetPassword(model)
      .subscribe({
        next: res => {
          this.submitting = false;
          if (!res) {
            return;
          }

          this.router.navigate(['/account/login']);
        },
        error: err => {
          this.submitting = false;
          // notity the error
        }
      }
      );
  }
}
