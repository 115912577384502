import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ViewChildren, QueryList, ElementRef } from '@angular/core';

import { formatDate } from '@angular/common';

import { JwtManagerService } from '../../_helpers';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { FormArray, FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

import { DataTableDirective } from 'angular-datatables';

import { PersonContactDto } from '../../_models';
import { GroupsService, CustomerGroupDto, ContactsService, ImportGroupsRequest, ImportGroupsData } from '../../_services';

import { Router, ActivatedRoute } from '@angular/router';

declare var $: any;

@Component({
  selector: 'customer-contact-list-component',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class CustomerGroupListComponent implements OnInit, AfterViewInit {
  dtOptions: DataTables.Settings = {};
  items: CustomerGroupDto[] = [];
  itemsDt: DataTableDirective;
  removing: boolean = false;

  editForm: FormGroup;
  savingForm: boolean = false;

  @ViewChildren(DataTableDirective)
  private datatableElements: QueryList<DataTableDirective>;


  contacts: PersonContactDto[];
  contactsDt: DataTableDirective;
  contactsDtOptions: DataTables.Settings = {};
  loadingContacts: boolean = false;
  selectedContacts: PersonContactDto[] = [];


  importingCsv: boolean = false;
  importResult: ImportGroupsData;
  selectedCsv: File = null;
  autoGenNames: boolean = false;
  @ViewChild('csvFile')
  csvFileElem: ElementRef;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly groupService: GroupsService,
    private readonly contactsService: ContactsService,
    private readonly jwtManager: JwtManagerService,
    private readonly fb: FormBuilder) {
  }

  ngOnInit(): void {
    var self = this;

    this.editForm = this.fb.group({
      id: [''],
      name: ['', [Validators.required]]
    }
    );

    // groups datatable
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      processing: true,
      responsive: true,
      autoWidth: false,
      searchDelay: 250,
      columns: [
        { data: 'id', visible: false, searchable: false },
        { data: 'name' },
        { data: 'createdOn' }
      ],
      order: [[1, 'asc']],
      ajax: (dataTablesParameters: any, callback) => {
        self.groupService.getGroupList(dataTablesParameters)
          .subscribe(resp => {
            self.items = resp.data;

            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          }
          );
      }
    };

    // contacts datatable
    this.contactsDtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      processing: true,
      responsive: true,
      autoWidth: false,
      searchDelay: 250,
      drawCallback: (s) => {
        //
      },
      columns: [
        { data: 'id', visible: false, searchable: false },
        { data: 'name' },
        { data: 'lastName' },
        { data: 'cell1' }
      ],
      order: [[1, 'asc']],
      ajax: (dataTablesParameters: any, callback) => {
        self.contactsService.getContactList(dataTablesParameters)
          .subscribe(resp => {
            self.contacts = resp.data;

            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          }
          );
      },

    };

    // enable selected view
    const selView = this.route.snapshot.paramMap.get('sview');
    if (selView === 'add') {
      this.addItem();
    }
  }

  ngAfterViewInit(): void {
    this.datatableElements.forEach((dtElement: DataTableDirective) => {
      var tableId = (<any>dtElement).el.nativeElement.id;

      // set dt items
      if (tableId === 'pickContactDt') {
        this.contactsDt = dtElement;
      } else if (tableId === 'groupsDt') {
        this.itemsDt = dtElement;
      }

      dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.on('draw.dt',
          () => {
            if ($('.dataTables_empty').length > 0) {
              $('.dataTables_empty').remove();
            }
          });
      });
    });
  }

  ngOnDestroy(): void {

  }

  refreshTable(): void {
    //this.datatableElements.forEach((dtElement: DataTableDirective) => {
    //  dtElement.dtInstance.then((dtInstance: DataTables.Api) => {

    //    dtInstance.draw(true);
    //  });
    //});

    this.itemsDt.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw(true);
    });
  }

  removeItem(item: any, index: number) {
    if (this.removing) {
      return;
    }

    if (!confirm(`Do you want to delete the group: ${item.name}`)) {
      return;
    }


    this.removing = true;

    this.groupService.removeGroup(item.id)
      .subscribe({
        next: res => {
          this.removing = false;
          if (res) {
            this.refreshTable();
          }
        },
        error: err => {
          this.removing = false;
          // notity the error
          // console.log('[ERR on removeGroup]', err);
        }
      }
      );
  }

  editItem(item: any) {
    this.editForm.reset({
      'id': item.id,
      'name': item.name
    });

    this.selectedContacts = [];

    if (item.id) {
      this.loadingContacts = true;

      this.groupService.getGroupMembers(item.id)
        .subscribe(
          members => {
            this.loadingContacts = false;
            this.selectedContacts = members;
          },
          error => {
            this.loadingContacts = false;
            // console.log("[GET GroupMembers error]", error);
          }
        );
    }

    $('#editorModal').modal('show');
  }

  addItem() {
    this.editItem({});
  }

  submitEditForm() {
    if (!this.editForm.valid) {
      return;
    }

    if (this.savingForm) {
      return;
    }

    const item = this.editForm.value as CustomerGroupDto;
    this.groupService.editGroup(item)
      .subscribe({
        next: res => {
          this.savingForm = false;

          if (res) {
            this.refreshTable();
            $('#editorModal').modal('hide');

            //-set the group members
            if (this.selectedContacts) {
              let selectedIds: string[] = [];
              for (let i = 0; i < this.selectedContacts.length; i++) {
                selectedIds.push(this.selectedContacts[i].id);
              }
              this.groupService.setGroupMembers(res, selectedIds)
                .subscribe(setMembersRes => {
                  // console.log("setGroupMembers", setMembersRes);
                },
                  error => err => {

                  }
                );
            } else {
            }
          }
        },
        error: err => {
          this.savingForm = false;
          // notity the error
          // console.log('saveGroup error', err);
        }
      }
      );
  }

  selectContact(contact, index) {
    const p = this.contacts[index] as PersonContactDto;
    let skip = false;
    for (let i = 0; i < this.selectedContacts.length; i++) {
      if (this.selectedContacts[i].id === p.id) {
        skip = true;
        break;
      }
    }
    if (!skip) {
      this.selectedContacts.push(p);
    }
  }

  deselectContact(contact, index) {
    let indexInSelectedContacts = -1;
    for (let i = 0; i < this.selectedContacts.length; i++) {
      if (this.selectedContacts[i].id === contact.id) {
        indexInSelectedContacts = i;
        break;
      }
    }
    if (indexInSelectedContacts >= 0) {
      this.selectedContacts.splice(indexInSelectedContacts, 1);
    }
  }

  formatDate(d): string {
    const dt = d as Date;
    if (!dt) {
      return '';
    }

    return formatDate(dt, 'MMM dd, yyyy', 'en-us');
  }

  // import group&contacts logic

  onSelectedCsvChanged(files: FileList) {
    if (!files || files.length === 0) {
      this.selectedCsv = null;
    }
    this.selectedCsv = files[0];
  }

  openImportCsv() {
    this.selectedCsv = null;
    this.importResult = null;
    this.importingCsv = false;
    this.csvFileElem.nativeElement.value = null;
    $('#contactImportModal').modal('show');
  }

  importCsv() {
    if (!this.selectedCsv || this.importingCsv) {
      return;
    }
    this.importingCsv = true;
    const request = new ImportGroupsRequest(this.selectedCsv, this.autoGenNames);

    this.groupService.importGroupAndContacts(request)
      .subscribe(res => {
        this.importingCsv = false;
        this.importResult = res;
        if (res) {
          this.refreshTable();
        }
      },
        error => (err) => {
          this.importingCsv = false;
        }
      );
  }

  isContactInGroup(c: PersonContactDto): boolean {
    if (!this.selectedContacts) return false;

    return this.selectedContacts.some((ci) => {
      return ci.cell1 === c.cell1;
    }
    );
  }
}
