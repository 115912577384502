import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService, LoginModel } from '../../_services';

@
Component({
  selector: 'customer-dashboard-component',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class CustomerDashboardComponent implements OnInit {

  constructor(private router: Router) {

  }

  ngOnInit(): void {

  }

  //this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url } });
  gotoMessages() {
    this.router.navigate(['/customer/messages']);
  }

  gotoContacts() {
    this.router.navigate(['/customer/contacts']);
  }

  gotoContactsAndAddNew() {
    this.router.navigate(['/customer/contacts', { sview: 'add' }]);
  }

  gotoContactsAndImportCsv() {
    this.router.navigate(['/customer/contacts', { sview: 'import' }]);
  }

  gotoGroups() {
    this.router.navigate(['/customer/groups']);
  }

  gotoGroupsAndAddNew() {
    this.router.navigate(['/customer/groups', { sview: 'add' }]);
  }


  gotoBlacklist() {
    this.router.navigate(['/customer/blacklist']);
  }

  gotoProfile() {
    this.router.navigate(['/customer/profile']);
  }

  gotoProfileAndEdit() {
    this.router.navigate(['/customer/profile', { sview: 'edit' }]);
  }

  gotoServices() {
    this.router.navigate(['/customer/services']);
  }

  gotoReports() {
    this.router.navigate(['/customer/reports']);
  }

  gotoMessagesReport() {
    this.router.navigate(['/customer/reports/messages']);
  }

  gotoScheduler(){
    this.router.navigate(['/customer/messages/scheduler']);
  }

  gotoSchedulerNew() {
    this.router.navigate(['customer/messages/scheduler/create']);
  }
}
