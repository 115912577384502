import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { formatDate } from '@angular/common';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DataTableDirective } from 'angular-datatables';

import {
  MessagesService, MessageCount, MessagesCountData, MessagesCountRequest,
  UserService, CustomerService, CustomerDto, RegisterCustomerDto, CountriesService,
  ServicePriceService, CustomerServicePrice, ServicePrice, ServicePriceBase, CustomerCountriesServicePrice,
  FormsService
} from '../../../_services';
import { ApiResponseBase, ApiResponseAny, DataTablesResponse, DataTablesResponseAny } from '../../../_services/_shared';
import { Address, ContactInfo, Country } from '../../../_models/index';

import { Router, ActivatedRoute } from '@angular/router';

declare var $: any;

@Component({
  selector: 'admin-manage-customers-component',
  templateUrl: './list.component.html',
  styles: []
})
export class ManageCustomersComponent implements OnInit {
  @ViewChild(DataTableDirective)
  private datatableElement: DataTableDirective;

  dtOptions: DataTables.Settings = {};
  items: CustomerDto[] = [];

  removing: boolean = false;

  editForm: FormGroup;
  savingForm: boolean = false;

  loadingCountries: boolean = false;
  countries: Country[] = null;

  changePasswordForm: FormGroup;
  changingPassword: boolean = false;

  messagesReportForm: FormGroup;
  loadingMessagesReport: boolean = false;
  messagesReportData: MessagesCountData = null;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly customerService: CustomerService,
    private readonly userService: UserService,
    private readonly countriesService: CountriesService,
    private readonly servicePriceService: ServicePriceService,
    private readonly messagesService: MessagesService,
    private readonly fb: FormBuilder,
    readonly formsService: FormsService) {
  }

  ngOnInit(): void {
    var self = this;

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      processing: true,
      responsive: true,
      autoWidth: false,
      searchDelay: 250,
      columns: [
        { data: 'id', visible: false, searchable: false },
        { data: 'name' },
        { data: 'lastName' },
        { data: 'username' },
        { data: 'email' },
        { data: 'address.country.isoCode' },
        { data: 'contact.phone' }
      ],
      order: [[1, 'asc']],
      ajax: (dataTablesParameters: any, callback) => {
        self.customerService.getListDt(dataTablesParameters)
          .subscribe(resp => {
            self.items = resp.data;

            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          }
          );
      }
    };

    this.editForm = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      lastName: ['', [Validators.required]],
      username: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      address: this.fb.group({
        streetAddress: ['', [Validators.required]],
        city: [''],
        zipCode: [''],
        country: this.fb.group({
          id: ['', [Validators.required]]
        })
      }),
      contact: this.fb.group({
        email: ['', [Validators.required, Validators.email]],
        phone: ['', Validators.pattern('[0-9]+')]
      })
    }
    );


    const checkPasswords = (group: FormGroup) => {
      const pass = group.controls.newPassword.value;
      const confirmPass = group.controls.newPasswordConfirm.value;

      return pass === confirmPass ? null : { notSame: true }
    }

    this.changePasswordForm = this.fb.group({
      id: ['', [Validators.required]],
      newPassword: ['', Validators.required],
      newPasswordConfirm: ['']
    },
      {
        validators: checkPasswords
      }
    );

    // messages report form
    const checkRangeDates = (group: FormGroup) => {
      const from = group.controls.from.value;
      const to = group.controls.to.value;
      if (!from || !to) {
        return null;
      }
      const dtFrom = new Date(from);
      const dtTo = new Date(to);
      return dtFrom <= dtTo ? null : { invalidRangeDates: true }
    }
    this.messagesReportForm = this.fb.group({
      userId: ['', [Validators.required]],
      from: ['', Validators.required],
      to: ['', Validators.required],
    },
      {
        validators: checkRangeDates
      }
    );

    // service price
    this.spEditForm = this.fb.group({
      customerId: ['', [Validators.required]],
      countryId: ['', [Validators.required]],
      inboundSmsPrice: ['', [Validators.required, Validators.min(0)]],
      outboundSmsPrice: ['', [Validators.required, Validators.min(0)]],
      mmsEnabled: [false],
      inboundMmsPrice: ['', [Validators.required, Validators.min(0)]],
      outboundMmsPrice: ['', [Validators.required, Validators.min(0)]]
    }
    );

    this.editNumberForm = this.fb.group({
      customerId: ['', [Validators.required]],
      number: ['', Validators.pattern('[0-9]+')],
    }
    );

    // enable selected view
    const selView = this.route.snapshot.paramMap.get('sview');
    if (selView === 'add') {
      this.addItem();
    }
  }


  refreshTable(): void {
    this.datatableElement.dtInstance
      .then(
        (dtInstance: DataTables.Api) => {
          dtInstance.draw(true);
        }
      );
  }

  isInEditMode(): boolean {
    const c = this.editForm.get('id');
    if (!c || !c.value) {
      return false;
    }
    return c.value !== null;
  }

  loadCountries() {
    if (this.loadingCountries) {
      return;
    }
    this.loadingCountries = true;

    this.countriesService.getList()
      .subscribe(
        res => {
          this.countries = res != null && res.countries != null ? res.countries : null;
          this.loadingCountries = false;
        },
        error => (err) => {
          // console.log('[GET countries] error', err);
          this.countries = null;
          this.loadingCountries = false;
        }
      );
  }


  editItem(item: any) {
    this.editForm.reset(item);
    if (item.id) {
      this.editForm.addControl('id', this.fb.control(item.id, [Validators.required]));
      this.editForm.removeControl('password');
    } else {
      this.editForm.removeControl('id');
      this.editForm.addControl('password', this.fb.control('', [Validators.required]));
    }

    if (this.countries == null) {
      this.loadCountries();
    }

    $('#itemEditorModal').modal('show');
  }

  addItem() {
    this.editItem({});
  }

  removeItem(item: any, i: number) {
    if (!item) {
      return;
    }

    if (this.removing) {
      return;
    }

    if (!confirm(`Do you want to delete the customer: ${item.name}`)) {
      return;
    }


    this.removing = true;

    this.userService.deleteUser(item.id)
      .subscribe({
        next: res => {
          if (res) {
            this.refreshTable();
          }
          this.removing = false;
        },
        error: err => {
          // notity the error
          // console.log('[ERR on remove account]', err);
          this.removing = false;
        }
      }
      );
  }

  submitEditForm() {
    if (!this.editForm.valid) {
      this.formsService.markFormGroupTouched(this.editForm);
      return;
    }

    if (this.savingForm) {
      return;
    }

    if (this.isInEditMode()) {
      const item = this.editForm.value as CustomerDto;

      this.customerService.updateCustomer(item)
        .subscribe({
          next: res => {
            this.savingForm = false;
            if (res) {
              this.refreshTable();
              $('#itemEditorModal').modal('hide');
            }
          },
          error: err => {
            // notity the error
            // console.log('updateCustomer error', err);
            this.savingForm = false;
          }
        }
        );
    } else {
      const item = this.editForm.value as RegisterCustomerDto;

      this.customerService.registerCustomer(item)
        .subscribe({
          next: res => {
            this.savingForm = false;
            if (res) {
              // res contains the registered customer
              this.refreshTable();
              $('#itemEditorModal').modal('hide');
            }
          },
          error: err => {
            // notity the error
            // console.log('registerCustomer error', err);
            this.savingForm = false;
          }
        }
        );
    }
  }

  changePassword(item: any) {
    this.changePasswordForm.reset(item);
    $('#changePasswordModal').modal('show');
  }

  submitChangePasswordForm() {
    if (!this.changePasswordForm.valid) {
      this.formsService.markFormGroupTouched(this.changePasswordForm);
      return;
    }

    if (this.changingPassword) {
      return;
    }

    this.changingPassword = true;

    let model = this.changePasswordForm.value as any;
    delete model.newPasswordConfirm;
    this.customerService.resetPassword(model)
      .subscribe(
        res => {
          this.changingPassword = false;
          $('#changePasswordModal').modal('hide');
        },
        error => (err) => {
          // console.log('resetPassword error', err);
          this.changingPassword = false;
        }
      );
  }

  openMessagesReportModal(item) {
    const beginningOfMonth = (d: any): Date => {
      let date = new Date(d);
      date.setDate(1);
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      return date;
    }

    this.messagesReportData = null;
    this.messagesReportForm.reset({
      userId: item.id,
      from: formatDate(beginningOfMonth(new Date()), 'yyyy-MM-dd', 'en-us'),
      to: formatDate(new Date(), 'yyyy-MM-dd', 'en-us')
    });
    $('#messagesReportModal').modal('show');
  }

  submitMessagesReportForm() {
    if (!this.messagesReportForm.valid) {
      this.formsService.markFormGroupTouched(this.messagesReportForm);
      return;
    }

    if (this.loadingMessagesReport) {
      return;
    }

    this.messagesReportData = null;
    this.loadingMessagesReport = true;

    let request = MessagesCountRequest.parse(this.messagesReportForm.value);
    this.messagesService.getMessagesCount(request)
      .subscribe(
        res => {
          this.messagesReportData = res;
          this.loadingMessagesReport = false;
        },
        error => (err) => {
          // console.log('messagesCountReport error', err);
          this.loadingMessagesReport = false;
        }
      );
  }

  // service price region
  loadingPriceForCountry: boolean = false;
  spEditForm: FormGroup;
  savingSpForm: boolean = false;
  loadingConfiguredSp: boolean = false;
  configuredSp: CustomerCountriesServicePrice = null;

  loadConfiguredSp(customerId) {
    if (this.loadingConfiguredSp) {
      return;
    }
    this.loadingConfiguredSp = true;
    this.servicePriceService.getCountriesPriceForCustomer(customerId)
      .subscribe(
        res => {
          this.configuredSp = res;
          this.loadingConfiguredSp = false;
        },
        error => (err) => {
          // console.log('[GET configuredSp] error', err);
          this.configuredSp = null;
          this.loadingConfiguredSp = false;
        }
      );
  }

  showSpEditor(customer: any) {
    if (!customer || !customer.id) {
      return;
    }
    this.spEditForm.reset();
    this.spEditForm.patchValue({ customerId: customer.id });

    if (!this.countries) {
      this.loadCountries();
    }

    this.loadingConfiguredSp = false;
    this.loadConfiguredSp(customer.id);

    $('#customerSpEditor').modal('show');
  }

  getCountryIso2ById(id: string): string {
    if (this.loadingCountries) {
      return null;
    }

    if (!this.countries) {
      return id;
    }

    let countryName = null;
    this.countries.some(c => {
      if (c.id === id) {
        countryName = c.isoCode;
        return true;
      }
      return false;
    });
    return countryName;
  }

  getCountryNameById(id: string): string {
    if (this.loadingCountries) {
      return null;
    }

    if (!this.countries) {
      return id;
    }

    let countryName = null;
    this.countries.some(c => {
      if (c.id === id) {
        countryName = c.name;
        return true;
      }
      return false;
    });
    return countryName;
  }

  onMmsEnabledChange(enabled) {
    if (!enabled) {
      this.spEditForm.patchValue({
        inboundMmsPrice: 0,
        outboundMmsPrice: 0
      }
      );
    }
  }

  submitSpEditForm() {
    if (!this.spEditForm.valid) {
      this.formsService.markFormGroupTouched(this.spEditForm);
      return;
    }

    if (this.savingSpForm) {
      return;
    }


    const customerId: string = this.spEditForm.get('customerId').value;
    const countryId: string = this.spEditForm.get('countryId').value;
    const priceSetup: ServicePriceBase = this.spEditForm.value as ServicePriceBase;

    if (priceSetup && !priceSetup.mmsEnabled) {
      priceSetup.inboundMmsPrice = 0;
      priceSetup.outboundMmsPrice = 0;
    }

    this.servicePriceService.setPriceForCustomer(customerId, countryId, priceSetup)
      .subscribe({
        next: res => {
          this.savingSpForm = false;

          this.loadingConfiguredSp = false;
          this.loadConfiguredSp(customerId);
        },
        error: err => {
          // notity the error
          // console.log('updateCustomerSp error', err);
          this.savingSpForm = false;
        }
      }
      );
  }

  onCountryChanged(countryId: string) {
    const customerId: string = this.spEditForm.get('customerId').value;

    if (!countryId) {
      this.spEditForm.reset({});
      this.spEditForm.patchValue({ customerId: customerId });
      return;
    }

    if (this.loadingPriceForCountry) {
      return;
    }
    this.loadingPriceForCountry = true;

    const fnProcessRes = (res) => {
      var resAny: any = (res || {}) as any;
      resAny.countryId = countryId;
      resAny.customerId = customerId;
      if (!resAny.mmsEnabled) {
        resAny.inboundMmsPrice = 0;
        resAny.outboundMmsPrice = 0;
      }
      this.spEditForm.reset(resAny);
    }

    const fnSpForCountry = () => {
      this.servicePriceService.getPriceForCountry(countryId)
        .subscribe(
          res2 => {
            fnProcessRes(res2);
            this.loadingPriceForCountry = false;
          },
          error2 => (err) => {
            // console.log('[GET ServicePrice4Country] error', err);
            this.loadingPriceForCountry = false;
          }
        );
    };

    this.servicePriceService.getPriceForCustomer(customerId, countryId)
      .subscribe(
        res => {
          if (res) {
            fnProcessRes(res);
            this.loadingPriceForCountry = false;
            return;
          }
          // if not value, then load service price for country (default values)
          fnSpForCountry();
        },
        error => (err) => {
          // console.log('[GET ServicePrice4Customer] error', err);
          this.loadingPriceForCountry = false;
        }
      );
  }

  deleteItem(item: any) {
    if (!item) return;

    let isSure = window.confirm(
      `You're going to remove the custom service price assigned to ${this.getCountryNameById(item.countryId)}. ` +
      `Do you want to continue?`
    );

    if (!isSure) return;

    const customerId: string = this.spEditForm.get('customerId').value;
    const countryId: string = item.countryId;

    this.loadingConfiguredSp = true;

    this.servicePriceService.removePriceForCustomer(customerId, countryId)
      .subscribe(
        res => {
          if (res) {
            this.configuredSp.count = this.configuredSp.count - 1;
            this.configuredSp.servicesPrice =
              this.configuredSp.servicesPrice.filter(obj => obj.countryId !== countryId);
          }
        },
        error => (err) => {
          // console.log('[DEL ServicePrice4Customer] error', err);
        }
      ).add(() => {
        this.loadingConfiguredSp = false;
      });
  }

  // end of service price


  // edit number

  editNumberForm: FormGroup;
  savingEditNumberForm: boolean = false;
  loadingNumber: boolean = false;
  showNumberEditor(customer: any) {
    if (!customer || !customer.id) {
      return;
    }
    this.editNumberForm.reset({
      customerId: customer.id
    });

    this.loadingNumber = true;
    // load the customer's number
    this.customerService.getAuthorizedNumberById(customer.id)
      .subscribe(
        res => {
          this.editNumberForm.patchValue({
            number: res
          });
          this.loadingNumber = false;
        },
        error => (err) => {
          // console.log('[GET CustomerNumber] error', err);
          this.loadingNumber = false;
        }
      );

    $('#editNumberModal').modal('show');
  }

  submitEditNumberForm() {
    if (!this.editNumberForm.valid) {
      this.formsService.markFormGroupTouched(this.editNumberForm);
      return;
    }

    if (this.savingEditNumberForm) {
      return;
    }

    this.savingEditNumberForm = true;

    const customerId: string = this.editNumberForm.get('customerId').value;
    const number = this.editNumberForm.get('number').value;

    this.customerService.setAuthorizedNumberById(customerId, number)
      .subscribe({
        next: res => {
          if (res) {
            $('#editNumberModal').modal('hide');
          } else {
            // check if need log/display error
          }
          this.savingEditNumberForm = false;
        },
        error: err => {
          // notity the error
          // console.log('setAuthorizedNumberById error', err);
          this.savingEditNumberForm = false;
        }
      }
      );
  }
  // end of edit number

}
