import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DataTableDirective } from 'angular-datatables';

import { ServicePriceService, CountryServicePrice, ServicePrice, ServicePriceBase, CountriesService, CountryListData, FormsService } from '../../../_services';
import { GatewayDto, CountryGatewayDto, GatewaysService } from '../../../_services';

import { ApiResponseBase, ApiResponseAny, DataTablesResponse, DataTablesResponseAny } from '../../../_services/_shared';
import { Address, ContactInfo, Country } from '../../../_models/index';

import { Router, ActivatedRoute } from '@angular/router';

declare var $: any;

@Component({
  selector: 'admin-manage-gateways-component',
  templateUrl: './list.component.html',
  styles: []
})
export class ManageGatewaysComponent implements OnInit {
  @ViewChild(DataTableDirective)
  private datatableElement: DataTableDirective;

  dtOptions: DataTables.Settings = {};
  items: CountryGatewayDto[] = [];

  removing: boolean = false;

  editingItem: boolean = false;
  editForm: FormGroup;
  savingForm: boolean = false;

  loadingCountries: boolean = false;
  loadingGatewayForCountry: boolean = false;
  countries: Country[] = null;

  loadingGateways: boolean = false;
  gateways: GatewayDto[] = null;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly servicePriceService: ServicePriceService,
    private readonly countriesService: CountriesService,
    private readonly fb: FormBuilder,
    private readonly formsService: FormsService,
    private readonly gatewaysService: GatewaysService) {
  }

  ngOnInit(): void {
    var self = this;

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      processing: true,
      responsive: true,
      autoWidth: false,
      //searchDelay: 250,
      paging: false,
      searching: false,
      columns: [
        { data: 'id', visible: false, searchable: false },
        { data: 'country.isoCode', orderable: false },
        { data: 'gateway.name', orderable: false }
      ],
      order: [[1, 'asc']],
      ajax: (dataTablesParameters: any, callback) => {
        self.gatewaysService.getAllCountryGateways()
          .subscribe(resp => {
            self.items = resp;

            callback({
              recordsTotal: resp != null ? resp.length : 0,
              recordsFiltered: 0,
              data: []
            });
          }
          );
      }
    };

    this.editForm = this.fb.group({
      countryId: ['', [Validators.required]],
      gateway: ['', [Validators.required]]
    }
    );


    // enable selected view
    const selView = this.route.snapshot.paramMap.get('sview');
    if (selView === 'add') {
      this.addItem();
    }
  }

  refreshTable(): void {
    this.datatableElement.dtInstance
      .then(
        (dtInstance: DataTables.Api) => {
          dtInstance.draw(true);
        }
      );
  }

  loadCountries() {
    if (this.loadingCountries) {
      return;
    }
    this.loadingCountries = true;

    this.countriesService.getList()
      .subscribe(
        res => {
          this.countries = res != null && res.countries != null ? res.countries : null;
          this.loadingCountries = false;
        },
        error => (err) => {
          // console.log('[GET countries] error', err);
          this.countries = null;
          this.loadingCountries = false;
        }
      );
  }

  loadGateways() {
    if (this.loadingGateways) {
      return;
    }
    this.loadingGateways = true;

    this.gatewaysService.getAll()
      .subscribe(
        res => {
          this.gateways = res != null ? res : null;
          this.loadingGateways = false;
        },
        error => (err) => {
          // console.log('[GET gateways] error', err);
          this.countries = null;
          this.loadingGateways = false;
        }
      );
  }

  editItem(item: any) {
    //item.countryId = item && item.country ? item.country.id : null; // patch item
    //item.gateway = item && item.gateway ? item.gateway.name : null; // patch item
    //this.editForm.reset(item);
    //this.editingItem = item && item.countryId;

    this.editForm.reset({
      countryId: item && item.country ? item.country.isoCode : null,
      gateway: item && item.gateway ? item.gateway.name : null,
    }
    );
    this.editingItem = true && this.editForm.get('countryId').value;


    if (!this.editingItem && this.countries == null) {
      this.loadCountries();
    }

    if (!this.loadingGateways && this.gateways == null) {
      this.loadGateways();
    }

    $('#itemEditorModal').modal('show');
  }

  addItem() {
    this.editItem({});
  }

  submitEditForm() {
    if (!this.editForm.valid) {
      this.formsService.markFormGroupTouched(this.editForm);
      return;
    }

    if (this.savingForm) {
      return;
    }

    const countryId: string = this.editForm.get('countryId').value;
    const gatewayName: string = this.editForm.get('gateway').value;

    this.gatewaysService.setCountryGateway(countryId, gatewayName)
      .subscribe({
        next: res => {
          this.savingForm = false;
          if (res) {
            this.refreshTable();
            $('#itemEditorModal').modal('hide');
          }
        },
        error: err => {
          // notity the error
          // console.log('add/update country gateway error', err);
          this.savingForm = false;
        }
      }
      );
  }

  onCountryChanged(countryId: string) {
    if (!countryId) {
      this.editForm.reset({});
      return;
    }

    if (this.loadingGatewayForCountry) {
      return;
    }
    this.loadingGatewayForCountry = true;

    this.gatewaysService.getCountryGateway(countryId)
      .subscribe(
        res => {
          var resAny = {
            countryId: countryId,
            gateway: res && res.gateway ? res.gateway.name : null
          };
          this.editForm.reset(resAny);
          this.loadingGatewayForCountry = false;
        },
        error => (err) => {
          // console.log('[GET CountryGateway] error', err);
          this.loadingGatewayForCountry = false;
        }
      );
  }

  onGatewayChanged(gateway: string) {

  }
}

