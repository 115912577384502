import { Router } from '@angular/router';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { NotifierService } from 'angular-notifier';
import { ApiResponseBase, ApiResponseAny, DataTablesResponse, DataTablesResponseAny } from './_shared';
import { Country } from '../_models';

@Injectable(({
  providedIn: 'root'
}) as any)
export class GatewaysService {
  private readonly apiUrlBase: string;

  constructor(
    @Inject('BASE_URL') private readonly baseUrl: string,
    private readonly http: HttpClient,
    private readonly notifier: NotifierService,
    private readonly router: Router) {
    this.apiUrlBase = `${this.baseUrl}/api/gateways`;
  }

  getAll(): Observable<GatewayDto[]> {
    return this.http.get<ApiResponseAny>(`${this.apiUrlBase}/getall`)
      .pipe(map(res => {
          if (ApiResponseAny.isSuccess(res)) {
            return res.data.gateways as GatewayDto[];
          }

          const message = res && res.message ? res.message : 'Error retrieving the gateways';
          this.notifier.notify('error', message);
          return null;
        })
      );
  }

  getAllCountryGateways(): Observable<CountryGatewayDto[]> {
    return this.http.get<ApiResponseAny>(`${this.apiUrlBase}/allcountry`)
      .pipe(map(res => {
          if (ApiResponseAny.isSuccess(res)) {
            return res.data.allGateways as CountryGatewayDto[];
          }

          const message = res && res.message ? res.message : 'Error retrieving the country gateways';
          this.notifier.notify('error', message);
          return null;
        })
      );
  }

  setCountryGateway(countryIso2: string, gatewayName: string): Observable<boolean> {
    return this.http.post<ApiResponseAny>(`${this.apiUrlBase}/set2country/${countryIso2}/${gatewayName}`, null)
      .pipe(map(res => {
        if (ApiResponseAny.isSuccess(res)) {
          return true;
        }

          const message = res && res.message ? res.message : "Error saving country's gateway";
          this.notifier.notify('error', message);
          return false;
        })
      );
  }

  getCountryGateway(countryIso2: string): Observable<CountryGatewayDto> {
    return this.http.get<ApiResponseAny>(`${this.apiUrlBase}/by-country/${countryIso2}`)
      .pipe(map(res => {
          if (ApiResponseAny.isSuccess(res)) {
            return res.data as CountryGatewayDto;
          }

          const message = res && res.message ? res.message : 'Error retrieving the country\'s gateway';
          this.notifier.notify('error', message);
          return null;
        })
      );
  }
}

export class GatewayDto {
  name: string;
}

export class CountryGatewayDto {
  id: string;
  country: Country;
  gateway: GatewayDto;
}
