import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService, ChangePasswordModel, FormsService } from '../../_services';

@Component({
  selector: 'change-password-component',
  templateUrl: './component.html',
  styleUrls: []
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm: FormGroup;
  changingPassword: boolean = false;

  constructor(
    readonly fb: FormBuilder,
    readonly router: Router,
    readonly userService: UserService,
    readonly formsService: FormsService) {

  }

  ngOnInit(): void {
    const checkNewPasswords = (group: FormGroup) => {
      const pass = group.controls.newPassword.value;
      const confirmPass = group.controls.newPasswordConfirm.value;

      return pass === confirmPass ? null : { notMatch: true }
    }

    const compareCurrentAndNewPasswords = (group: FormGroup) => {
      const currentPassword = group.controls.currentPassword.value;
      const newPassword = group.controls.newPassword.value;

      return currentPassword && currentPassword === newPassword ? { same: true } : null;
    }

    this.changePasswordForm = this.fb.group({
      currentPassword: ['', [Validators.required]],
      newPassword: ['', Validators.required],
      newPasswordConfirm: ['']
    },
      {
        validators: [checkNewPasswords, compareCurrentAndNewPasswords]
      }
    );
  }

  submitChangePasswordForm() {
    if (this.changingPassword) {
      return;
    }

    if (this.changePasswordForm.invalid) {
      this.formsService.markFormGroupTouched(this.changePasswordForm);
      return;
    }

    this.changingPassword = true;

    const model = ChangePasswordModel.parse({
      currentPassword: this.changePasswordForm.get('currentPassword').value,
      newPassword: this.changePasswordForm.get('newPassword').value,
    });

    this.userService.changePassword(model)
      .subscribe({
        next: res => {
          if (res) {
            this.changePasswordForm.reset();
          }
          // res: boolean
          this.changingPassword = false;
        },
        error: (err): void => {
          // notity the error
          this.changingPassword = false;
        }
      }
      );
  }
}
