import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
import { UserService, LoginModel } from '../../_services';
import { JwtHelperService } from '../../_helpers';

@Component({
  selector: 'login-component',
  templateUrl: './login.component.html',
  styleUrls: []
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitting: boolean = false;
  returnUrl: string;

  constructor(
    readonly fb: FormBuilder,
    readonly router: Router,
    readonly userService: UserService,
    readonly activatedRoute: ActivatedRoute,
    readonly jwtHelper: JwtHelperService) {
    this.returnUrl = this.activatedRoute.snapshot.queryParamMap.get('returnUrl');
  }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      username: ['', [Validators.required, Validators.minLength(3)]],
      password: ['', [Validators.required, Validators.minLength(2)]],
    });
  }


  onSubmit() {
    if (this.submitting) {
      return;
    }

    if (this.loginForm.invalid) {
      return;
    }

    this.submitting = true;

    const model = new LoginModel(
      this.loginForm.get('username').value,
      this.loginForm.get('password').value
    );

    this.userService.login(model)
      .subscribe({
        next: loginData => {
          this.submitting = false;

          if (!loginData) {
            return;
          }

          if (!this.returnUrl) {
            // determine the dashboard based in role
            let isAdmin = false, isCustomer = false;
            try {
              const tokenPayload = this.jwtHelper.decodeToken(this.jwtHelper.getToken());
              const roles = tokenPayload.role; // array of string
              isAdmin = roles && roles.indexOf('Admin') >= 0;
              isCustomer = roles && roles.indexOf('Customer') >= 0;
            } catch (e) {
              // console.log(`error decoding token: ${JSON.stringify(e)}`);
            }

            if (isAdmin) {
              this.returnUrl = 'admin/dashboard';
            } else if (isCustomer) {
              this.returnUrl = 'customer/dashboard';
            } else {
              this.returnUrl = '/';
            }
          }

          this.router.navigate([this.returnUrl]);
        },
        error: err => {
          // notity the error
          this.submitting = false;
        }
      }
      );
  }
}
