import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { CanActivate, Router, ActivatedRoute } from "@angular/router";
import { UserService, ResetPasswordModel } from '../../_services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'verify-email-component',
  templateUrl: './component.html',
  styleUrls: []
})
export class VerifyEmailComponent implements OnInit {
  theForm: FormGroup;
  submitting: boolean = false;
  verificationFailed: boolean = false;

  constructor(
    readonly router: Router,
    readonly userService: UserService,
    readonly activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    const verifyToken = this.activatedRoute.snapshot.queryParamMap.get('token') || null;

    if (!verifyToken) {
      this.verificationFailed = true;
      return;
    }

    this.userService.completeRegistration(verifyToken)
      .subscribe({
        next: res => {
          this.submitting = false;
          if (!res) {
            return;
          }

          this.router.navigate(['/account/login']);
        },
        error: err => {
          this.submitting = false;
          // notity the error
        }
      }
      );
  }
}
