import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Injectable } from "@angular/core";
import { JwtHelperService } from '../_helpers/jwt.helperservice';
import { AuthGuard } from './auth-guard.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { NotifierService } from 'angular-notifier';

@Injectable(({
  providedIn: 'root',
}) as any)
export class RoleGuard implements CanActivate {

  constructor(
    private readonly jwtHelper: JwtHelperService,
    private readonly router: Router,
    private readonly authGuard: AuthGuard,
    private readonly notifier: NotifierService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authGuard.canActivate(route, state)
      .pipe(map(res => {
        if (!res) {
          // not logged in so redirect to login page with the return url
          this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url } });
          return false;
        }

        const tokenPayload = this.jwtHelper.decodeToken(this.jwtHelper.getToken());

        // this will be passed from the route config on the data property
        const expectedRole = route.data.expectedRole;

        if (!tokenPayload || tokenPayload.role.indexOf(expectedRole) < 0) {
          // not in role so redirect to login page with the return url
          this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url } });
          this.notifier.notify('error', `Role required: ${expectedRole}.`);
          return false;
        }

        return true;
      }));
  }
}
