import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Injectable } from "@angular/core";
import { JwtHelperService } from '../_helpers/jwt.helperservice';
import { Observable, of } from 'rxjs';

import { NotifierService } from 'angular-notifier';

@Injectable({
  providedIn: 'root',
} as any)
export class AuthGuard implements CanActivate {
  constructor(private jwtHelper: JwtHelperService, private router: Router, private notifier: NotifierService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : Observable<boolean> {
    var token = this.jwtHelper.getToken();
    
    //if (token && !this.jwtHelper.isTokenExpired(token)) {
    if (token) {
      return of(true);
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url } });
    this.notifier.notify('error', 'Protected resource, please login.');
    return of(false);
  }
}
