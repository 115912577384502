import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from "@angular/router";
import { UserService, ForgotPasswordModel } from '../../_services';

@Component({
  selector: 'forgot-password-component',
  templateUrl: './component.html',
  styleUrls: []
})
export class ForgotPasswordComponent implements OnInit {
  theForm: FormGroup;
  submitting: boolean = false;

  constructor(
    readonly fb: FormBuilder,
    readonly router: Router,
    readonly userService: UserService) {

  }

  ngOnInit(): void {
    this.theForm = this.fb.group({
      emailOrUsername: ['', [Validators.required, Validators.minLength(3)]],
    });
  }

  onSubmit() {
    if (this.submitting) {
      return;
    }

    if (this.theForm.invalid) {
      return;
    }

    this.submitting = true;

    const model = new ForgotPasswordModel(
      this.theForm.get('emailOrUsername').value
    );

    this.userService.forgotPassword(model)
      .subscribe({
        next: res => {
          this.submitting = false;
          if (!res) {
            return;
          }

          this.router.navigate(['/account/reset-password']);
        },
        error: err => {
          this.submitting = false;
          // notity the error
        }
      }
      );
  }
}
