import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  HttpClientModule, HTTP_INTERCEPTORS,
  HttpClientXsrfModule
} from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { DataTablesModule } from 'angular-datatables';

// anonymous
import { HomeComponent } from './home/home.component';

// all type of user
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { LoginComponent } from './account/login/login.component';
import { ForgotPasswordComponent } from './account/forgot-password/component';
import { ResetPasswordComponent } from './account/reset-password/component';
import { VerifyEmailComponent } from './account/verify-email/component';
import { ChangePasswordComponent } from './account/change-password/component';


// customers
import { CustomerDashboardComponent } from './customer/dashboard/dashboard.component';
import { CustomerProfileComponent } from './customer/profile/component';
import { CustomerBlackListComponent } from './customer/blacklist/component';
import { CustomerContactListComponent } from './customer/contacts/list.component';
import { CustomerGroupListComponent } from './customer/groups/list.component';
import { MessagesCenterComponent } from './customer/messages/center.component';
import { CustomerReportsIndexComponent } from './customer/reports/index/index.component';
import { CustomerMessagesReport } from './customer/reports/messages-r1/r1.component';
import { CustomerServicesPrices } from './customer/services/prices/component';
import { CustomerMessagesReportFull } from './customer/reports/messages-r2-full/r2.component';

// admin
import { AdminDashboardComponent } from './admin/dashboard/dashboard.component';
import { ManageCustomersComponent } from './admin/manage/customers/list.component';
import { ManageServicesPriceComponent } from './admin/manage/services/list.component';
import { AdminReportsIndexComponent } from './admin/reports/index/index.component';
import { AdminCustomerMessagesReport1 } from './admin/reports/customer/messages-r1/r1.component';
import { ManageGatewaysComponent } from './admin/manage/gateways/list.component';
import { AdminCustomerMessagesReportFull } from './admin/reports/customer/messages-r2-full/r2.component';
import { AdminCustomersMessagesReportFull } from './admin/reports/customer/messages-r2-full-variant2/r2.component';

import { NotifierModule } from 'angular-notifier';

import { JwtInterceptor, CopyClipboardModule } from './_helpers';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ScrollingModule as ExperimentalScrollingModule } from '@angular/cdk-experimental/scrolling';

// Import the library module
import { AngularResizedEventModule } from 'angular-resize-event';
import { ScheduledMessagesListComponent } from './customer/scheduled-messages/scheduled-messages-list/scheduled-messages-list.component';
import { ScheduledMessagesCreateComponent } from './customer/scheduled-messages/scheduled-messages-create/scheduled-messages-create.component';
import { Select2Module } from 'ng-select2-component';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,

    // app components
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    VerifyEmailComponent,
    ChangePasswordComponent,

    // customers
    CustomerDashboardComponent,
    CustomerProfileComponent,
    CustomerBlackListComponent,
    CustomerContactListComponent,
    CustomerGroupListComponent,
    MessagesCenterComponent,
    CustomerReportsIndexComponent,
    CustomerMessagesReport,
    CustomerServicesPrices,
    CustomerMessagesReportFull,

    // admins
    AdminDashboardComponent,
    ManageCustomersComponent,
    ManageServicesPriceComponent,
    AdminReportsIndexComponent,
    AdminCustomerMessagesReport1,
    ManageGatewaysComponent,
    AdminCustomerMessagesReportFull,
    AdminCustomersMessagesReportFull,

    // unsorted
    HomeComponent,

    ScheduledMessagesListComponent,

    ScheduledMessagesCreateComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserAnimationsModule,
    RouterModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'XSRF-TOKEN',
      headerName: 'X-CSRF-TOKEN'
    }),
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    DataTablesModule,
    CopyClipboardModule,
    ScrollingModule, ExperimentalScrollingModule, AngularResizedEventModule,
    NotifierModule.withConfig({
      // Custom options in here. Read more https://www.npmjs.com/package/angular-notifier
      position: {
        horizontal: {
          position: 'middle'
        },
        vertical: {
          position: 'bottom',
          //distance: 68
        }
      },
      behaviour: {
        autoHide: 3000,
        stacking: 1
      }
    }),
    Select2Module,
    NgbModule,
    ToastrModule.forRoot()
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
