import { Router } from '@angular/router';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { NotifierService } from 'angular-notifier';
import { ApiResponseBase, ApiResponseAny, DataTablesResponseAny, DataTablesResponse } from './_shared';
import { PersonContactDto } from '../_models';

@Injectable(({
  providedIn: 'root'
}) as any)
export class GroupsService {
  private readonly apiUrlBase: string;

  constructor(
    @Inject('BASE_URL') private readonly baseUrl: string,
    private readonly http: HttpClient,
    private readonly router: Router,
    private readonly notifier: NotifierService) {
    this.apiUrlBase = `${this.baseUrl}/api/groups`;
  }

  getGroupList(datatablesParameters: any): Observable<DataTablesResponse<CustomerGroupDto>> {
    return this.http.post<DataTablesResponse<CustomerGroupDto>>(
      `${this.apiUrlBase}/get-dt`,
      datatablesParameters
    );
  }

  editGroup(item: CustomerGroupDto): Observable<string> {
    const url = `${this.apiUrlBase}/${item.id ? 'edit' : 'add'}`;
    return this.http.post<ApiResponseAny>(url, item)
      .pipe(map(res => {
          if (ApiResponseAny.isSuccess(res)) {
            return item.id || res.data;
          }

          this.notifier.notify('error', res.message || 'Save group failed');
          return null;
        })
      );
  }

  removeGroup(id: string): Observable<boolean> {
    return this.http.post<ApiResponseAny>(`${this.apiUrlBase}/${id}/remove`, {})
      .pipe(map(res => {
          if (ApiResponseAny.isSuccess(res)) {
            return true;
          }

          this.notifier.notify('error', res.message || 'Delete group failed');
          return false;
        })
      );
  }

  removeGroupContact(groupId: string, contactId: string) {
    return this.http.post<ApiResponseAny>(`${this.apiUrlBase}/${groupId}/remove-contact/${contactId}`, {})
      .pipe(map(res => {
          if (ApiResponseAny.isSuccess(res)) {
            return true;
          }

          this.notifier.notify('error', res.message || 'Delete group contact failed');
          return false;
        })
      );
  }

  addGroupContact(groupId: string, contactId: string) {
    return this.http.post<ApiResponseAny>(`${this.apiUrlBase}/${groupId}/add_contact/${contactId}`, {})
      .pipe(map(res => {
          if (ApiResponseAny.isSuccess(res)) {
            return true;
          }

          this.notifier.notify('error', res.message || 'Add group contact failed');
          return false;
        })
      );
  }

  getGroupMembers(groupId: string) : Observable<PersonContactDto[]> {
    return this.http.get<ApiResponseBase<PersonContactDto[]>>(`${this.apiUrlBase}/${groupId}/members`)
      .pipe(map(res => {
          if (ApiResponseAny.isSuccess(res)) {
            return res.data;
          }

          this.notifier.notify('error', res.message || 'Unable to read group members');
          return [];
        })
      );
  }

  setGroupMembers(groupId: string, memberIds: string[]): Observable<boolean> {
    return this.http.post<ApiResponseBase<any>>(`${this.apiUrlBase}/${groupId}/members`, { ids : memberIds })
      .pipe(map(res => {
          if (ApiResponseAny.isSuccess(res)) {
            return true;
          }

          this.notifier.notify('error', res.message || 'Unable to set the group members');
          return false;
        })
      );
  }

  getMeGroups(): Observable<CustomerGroupsData> {
    return this.http.get<ApiResponseBase<CustomerGroupsData>>(`${this.apiUrlBase}/me`)
      .pipe(map(res => {
          if (ApiResponseAny.isSuccess(res)) {
            return res.data;
          }

          this.notifier.notify('error', res.message || 'Unable to retrieve the groups');
          return null;
        })
      );
  }

  importGroupAndContacts(request: ImportGroupsRequest): Observable<ImportGroupsData> {
    let formData = new FormData();
    formData.append('file', request.file, request.file.name);
    formData.append('autoGenNames', request.autoGenNames ? 'true' : 'false');

    return this.http.post<ApiResponseBase<ImportGroupsData>>(`${this.apiUrlBase}/import`, formData)
      .pipe(map(res => {
          if (ApiResponseAny.isSuccess(res)) {
            return res.data;
          }

          this.notifier.notify('error', res.message || 'Import group&contacts has failed');
          return null;
        })
      );
  }
}

export class CustomerGroupDto {
  id: string;
  name: string;
  createdOn: string;

  static parse(input: any): CustomerGroupDto {
    return input as CustomerGroupDto;
  }
}

export class CustomerGroupsData {
  count: number;
  groups: Array<CustomerGroupDto>;

  static parse(input: any): CustomerGroupsData {
    return input as CustomerGroupsData;
  }
}

export class ImportGroupsRequest {
  constructor(
    public readonly file: File,
    public readonly autoGenNames?: boolean) {
  }
}

export class ImportGroupsData {
  invalid: number;
  newGroups: number;
  newContacts: number;
  groupsInfo: any;
}

export class GroupImportInfo {
  imported: number;
  ignored: number;
}
