import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';

import {
  UserService, CustomerDto, CustomerService,
  FormsService, MessagesService,
  CustomerMessagesReportFullData, SentMessageInfo, ReceivedMessageInfo, CustomerMessagesReportFullRequest
} from '../../../_services';

import { DataTableDirective } from 'angular-datatables';

declare var $: any;

@Component({
  selector: 'customer-messages-report-full',
  templateUrl: './r2.component.html',
  styleUrls: ['./r2.component.css']
})
export class CustomerMessagesReportFull implements OnInit {
  private readonly stepSelectDateRange: number = 1;
  private readonly stepDisplayReport: number = 2;

  showSentDetails: boolean;
  showInboundDetails: boolean;

  step: number;

  form: FormGroup;

  loadingReport: boolean = false;
  reportData: CustomerMessagesReportFullData = null;

  constructor(
    private readonly router: Router,
    private readonly customerService: CustomerService,
    private readonly messagesService: MessagesService,
    private readonly fb: FormBuilder,
    readonly formsService: FormsService) {
  }


  ngOnInit(): void {
    const self = this;

    // messages report form
    const checkRangeDates = (group: FormGroup) => {
      const from = group.controls.from.value;
      const to = group.controls.to.value;
      if (!from || !to) {
        return null;
      }
      const dtFrom = new Date(from);
      const dtTo = new Date(to);
      return dtFrom <= dtTo ? null : { invalidRangeDates: true }
    };

    this.form = this.fb.group(
      {
        from: ['', [Validators.required]],
        to: ['', Validators.required],
      },
      {
        validators: checkRangeDates
      }
    );
    this.startAgain();
  }

  resetDateRange() {
    const beginningOfMonth = (d: any): Date => {
      let date = new Date(d);
      date.setDate(1);
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      return date;
    };

    this.form.patchValue({
      from: formatDate(beginningOfMonth(new Date()), 'yyyy-MM-dd', 'en-us'),
      to: formatDate(new Date(), 'yyyy-MM-dd', 'en-us')
    });
  }

  goToSelectDate() {
    if (this.form.get('userId').invalid) {
      return;
    }
    this.step = this.stepSelectDateRange;
  }

  submitForm() {
    if (this.form.invalid || this.loadingReport) {
      return;
    }

    this.loadingReport = true;
    this.reportData = null;
    this.step = this.stepDisplayReport;

    let request = <any>this.form.value as CustomerMessagesReportFullRequest;
    this.messagesService.getCurrentCustomerMessagesReportFull(request)
      .subscribe({
        next: res => {
          this.reportData = res;
        },
        error: err => {
          // notity the error
          // console.log('MessagesReportFull error', err);
        }
      }
      ).add(() => {
        this.loadingReport = false;
      }
      );
  }

  startAgain() {
    this.resetDateRange();
    this.reportData = null;
    this.step = this.stepSelectDateRange;
  }

}
