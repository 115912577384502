import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JwtManagerService } from '../_helpers/jwt.manager';
import { NotifierService } from 'angular-notifier';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResponseAny, ApiResponseBase } from './_shared'

@Injectable({
  providedIn: 'root'
} as any)
export class UserService {
  private readonly usersApiUrlBase: string;

  private defaultPostApiCall(res: ApiResponseAny, defaultSuccessMessage?: string, defaultFailMessage?: string):
    boolean {
    const isSuccess = ApiResponseAny.isSuccess(res);
    const message = res && res.message
      ? res.message
      : (isSuccess ? defaultSuccessMessage : defaultFailMessage) || null;

    if (message) {
      this.notifier.notify((isSuccess ? 'success' : 'error'), message);
    }
    return isSuccess;
  }

  constructor(
    @Inject('BASE_URL') private readonly baseUrl: string,
    private readonly http: HttpClient,
    private readonly jwtManager: JwtManagerService,
    private readonly router: Router,
    private readonly notifier: NotifierService) {
    this.usersApiUrlBase = `${this.baseUrl}/api/users`;
  }

  register(registerModel: RegisterModel) {
    return this.http.post<ApiResponseAny>(`${this.usersApiUrlBase}/register`, registerModel)
      .pipe(map(res => {
        return this.defaultPostApiCall(res, 'Registration success', 'Registration failed');
      })
      );
  }

  completeRegistration(token: string) {
    return this.http.get<ApiResponseAny>(`${this.usersApiUrlBase}/complete_register/${token}`)
      .pipe(map(res => {
        return this.defaultPostApiCall(res, 'Complete registration success', 'Complete registration has failed');
      })
      );
  }

  login(model: LoginModel): Observable<boolean> {
    return this.http.post<ApiResponseAny>(`${this.usersApiUrlBase}/login`, model)
      .pipe(map(res => {
        //console.log(`[UserService LOGIN] ${JSON.stringify(res)}`);

        // login successful if there's a jwt token in the response
        if (ApiResponseAny.isSuccess(res)) {
          this.notifier.notify('success', 'Welcome...');
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          this.jwtManager.setToken(res.data.accessToken);
          this.jwtManager.setRefreshToken(res.data.refreshToken);

          return res.data;
        }

        const message = res && res.message ? res.message : 'Login failed';
        this.notifier.notify('error', message);
        return null;
      })
      );
  }

  refreshToken(accessToken: string, refreshToken: string): Observable<LoginUserData> {
    const data = {
      accessToken: accessToken,
      refreshToken: refreshToken
    };
    return this.http.post<ApiResponseBase<LoginUserData>>(`${this.usersApiUrlBase}/refresh-token`, data)
      .pipe(map(res => {
        if (ApiResponseAny.isSuccess(res)) {
          this.jwtManager.setToken(res.data.accessToken);
          this.jwtManager.setRefreshToken(res.data.refreshToken);

          return res.data;
        }

        return null;
      })
      );
  }

  deleteUser(userId: string) {
    return this.http.delete<ApiResponseAny>(`${this.usersApiUrlBase}/delete/${userId}`)
      .pipe(map(res => {
        return this.defaultPostApiCall(res, 'The user has been deleted', 'Delete user has failed');
      })
      );
  }

  logout() {
    // remove user from local storage to log user out
    this.http.get(`${this.usersApiUrlBase}/logout`)
      .subscribe(res => {
        // console.log(`[LOGOUT] ${JSON.stringify(res)}`);
      }
      );

    this.jwtManager.removeToken();
  }

  forgotPassword(model: ForgotPasswordModel) {
    return this.http.post<ApiResponseAny>(`${this.usersApiUrlBase}/forgotpassword`, model)
      .pipe(map(res => {
        return this.defaultPostApiCall(res, 'Forgot password request sent', 'Forgot password has failed');
      })
      );
  }

  resetPassword(model: ResetPasswordModel) {
    return this.http.post<ApiResponseAny>(`${this.usersApiUrlBase}/resetpassword`, model)
      .pipe(map(res => {
        return this.defaultPostApiCall(res, 'Password reseted', 'Reset password has failed');
      })
      );
  }

  changePassword(model: ChangePasswordModel) {
    return this.http.post<ApiResponseAny>(`${this.usersApiUrlBase}/changepassword`, model)
      .pipe(map(res => {
        return this.defaultPostApiCall(res, 'Password changed', 'Unable to change the password');
      })
      );
  }
}

export class LoginUserData {
  accessToken: string;
  refreshToken: string;
}

export class RegisterModel {
  constructor(public username: string, public email: string, public password: string) { }

  static parse(input: any): RegisterModel {
    return new RegisterModel(input.username, input.email, input.password);
  }
}

export class LoginModel {
  constructor(public username: string, public password: string) { }

  static parse(input: any): LoginModel {
    return new LoginModel(input.username, input.password);
  }
}

export class ForgotPasswordModel {
  constructor(public emailOrUsername: string) { }

  static parse(input: any): ForgotPasswordModel {
    return new ForgotPasswordModel(input.emailOrUsername);
  }
}

export class ResetPasswordModel {
  constructor(public token: string, public newPassword: string) { }

  static parse(input: any): ResetPasswordModel {
    return new ResetPasswordModel(input.token, input.newPassword);
  }
}

export class ChangePasswordModel {
  currentPassword: string;
  newPassword: string;

  static parse(input: any): ChangePasswordModel {
    return input as ChangePasswordModel;
  }
}
