import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';

import { AuthGuard, RoleGuard } from './_guards';

import { LoginComponent } from './account/login/login.component';
import { ForgotPasswordComponent } from './account/forgot-password/component';
import { ResetPasswordComponent } from './account/reset-password/component';
import { VerifyEmailComponent } from './account/verify-email/component';
import { ChangePasswordComponent } from './account/change-password/component';

import { CustomerDashboardComponent } from './customer/dashboard/dashboard.component';
import { CustomerProfileComponent } from './customer/profile/component';
import { CustomerBlackListComponent } from './customer/blacklist/component';
import { CustomerContactListComponent } from './customer/contacts/list.component';
import { CustomerGroupListComponent } from './customer/groups/list.component';
import { MessagesCenterComponent } from './customer/messages/center.component';
import { CustomerReportsIndexComponent } from './customer/reports/index/index.component';
import { CustomerMessagesReport } from './customer/reports/messages-r1/r1.component';
import { CustomerServicesPrices } from './customer/services/prices/component';
import { CustomerMessagesReportFull } from './customer/reports/messages-r2-full/r2.component';

import { AdminDashboardComponent } from './admin/dashboard/dashboard.component';
import { ManageCustomersComponent } from './admin/manage/customers/list.component';
import { ManageServicesPriceComponent } from './admin/manage/services/list.component';
import { AdminReportsIndexComponent } from './admin/reports/index/index.component';
import { AdminCustomerMessagesReport1 } from './admin/reports/customer/messages-r1/r1.component';
import { ManageGatewaysComponent } from './admin/manage/gateways/list.component';
import { AdminCustomerMessagesReportFull } from './admin/reports/customer/messages-r2-full/r2.component';
import { AdminCustomersMessagesReportFull } from './admin/reports/customer/messages-r2-full-variant2/r2.component';
import { ScheduledMessagesListComponent } from './customer/scheduled-messages/scheduled-messages-list/scheduled-messages-list.component';
import { ScheduledMessagesCreateComponent } from './customer/scheduled-messages/scheduled-messages-create/scheduled-messages-create.component';

const userRoles = {
  Admin: 'Admin',
  Customer: 'Customer',
};

const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full', data: { title: 'Home' } },

  //app paths
  // -account
  { path: 'account/login', component: LoginComponent, data: { title: 'Login' } },
  { path: 'account/forgot-password', component: ForgotPasswordComponent, data: { title: 'Forgot Password' } },
  { path: 'account/reset-password', component: ResetPasswordComponent, data: { title: 'Reset Password' } },
  { path: 'account/verify-email', component: VerifyEmailComponent, data: { title: 'Verify Email' } },
  {
    path: 'account/changepassword',
    component: ChangePasswordComponent,
    canActivate: [AuthGuard],
    data: { title: 'Change Password' }
  },
  // customers
  {
    path: 'customer/dashboard',
    component: CustomerDashboardComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: userRoles.Customer,
      title: 'Dashboard'
    }
  },
  {
    path: 'customer/profile',
    component: CustomerProfileComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: userRoles.Customer,
      title: 'Profile'
    }
  },
  {
    path: 'customer/blacklist',
    component: CustomerBlackListComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: userRoles.Customer,
      title: 'Blacklist'
    }
  },
  {
    path: 'customer/contacts',
    component: CustomerContactListComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: userRoles.Customer,
      title: 'Contacts'
    }
  },
  {
    path: 'customer/groups',
    component: CustomerGroupListComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: userRoles.Customer,
      title: 'Groups'
    }
  },
  {
    path: 'customer/messages',
    component: MessagesCenterComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: userRoles.Customer,
      title: 'Messages'
    }
  },
  {
    path: 'customer/services',
    component: CustomerServicesPrices,
    canActivate: [RoleGuard],
    data: {
      expectedRole: userRoles.Customer,
      title: 'Services Prices'
    }
  },
  {
    path: 'customer/reports', pathMatch: 'full',
    component: CustomerReportsIndexComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: userRoles.Customer,
      title: 'Reports'
    }
  },
  {
    path: 'customer/reports/messages',
    component: CustomerMessagesReport,
    canActivate: [RoleGuard],
    data: {
      expectedRole: userRoles.Customer,
      title: 'Messages Report'
    }
  },
  {
    path: 'customer/reports/messages-full',
    component: CustomerMessagesReportFull,
    canActivate: [RoleGuard],
    data: {
      expectedRole: userRoles.Customer,
      title: 'All Messages Report'
    }
  },
  {
    path: 'customer/messages/scheduler',
    redirectTo: 'customer/messages/scheduler/list'
  },
  {
    path: 'customer/messages/scheduler/list',
    component: ScheduledMessagesListComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: userRoles.Customer,
      title: 'Messages Scheduler'
    }
  },
  {
    path: 'customer/messages/scheduler/create',
    component: ScheduledMessagesCreateComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: userRoles.Customer,
      title: 'Schedule New Message'
    }
  },

  //admins
  {
    path: 'admin/dashboard',
    component: AdminDashboardComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: userRoles.Admin,
      title: 'Dashboard'
    }
  },
  {
    path: 'admin/manage/customers',
    component: ManageCustomersComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: userRoles.Admin,
      title: 'Customers'
    }
  },
  {
    path: 'admin/manage/services',
    component: ManageServicesPriceComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: userRoles.Admin,
      title: 'Services'
    }
  },
  {
    path: 'admin/manage/gateways',
    component: ManageGatewaysComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: userRoles.Admin,
      title: 'Gateways'
    }
  },
  {
    path: 'admin/reports', pathMatch: 'full',
    component: AdminReportsIndexComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: userRoles.Admin,
      title: 'Reports'
    }
  },
  {
    path: 'admin/reports/customer/messages',
    component: AdminCustomerMessagesReport1,
    canActivate: [RoleGuard],
    data: {
      expectedRole: userRoles.Admin,
      title: 'Messages Report'
    }
  },
  {
    path: 'admin/reports/customer/messages-full',
    component: AdminCustomersMessagesReportFull,
    canActivate: [RoleGuard],
    data: {
      expectedRole: userRoles.Admin,
      title: 'All Messages Report'
    }
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
