import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';

import {
  UserService, CustomerDto, CustomerService,
  CustomerMessagesReportByCountryRequest, CustomerMessagesReportByCountryData, CustomerMessagesReportByCountry, SummaryInfo, MessagesService,
  CustomerMessagesReportFullRequest, CustomerMessagesReportFullData, SentMessageInfo, ReceivedMessageInfo,
  FormsService,
  CustomersMessagesReportFullRequest,
  CustomersMessagesReportFullData
} from '../../../../_services';

import { DataTableDirective } from 'angular-datatables';

declare var $: any;

@Component({
  selector: 'admin-customer-messages-report-full-variant2',
  templateUrl: './r2.component.html',
  styleUrls: ['./r2.component.css']
})
export class AdminCustomersMessagesReportFull implements OnInit {
  private readonly stepSelectCustomer: number = 0;
  private readonly stepSelectDateRange: number = 1;
  private readonly stepDisplayReport: number = 2;

  showSentDetails:boolean;
  showInboundDetails:boolean;

  step: number;

  selectedCustomers: CustomerDto[] = [];
  hasSelectedCustomers: boolean = false;
  form: FormGroup;

  @ViewChild(DataTableDirective)
  private datatableElement: DataTableDirective;

  dtOptions: DataTables.Settings = {};
  items: CustomerDto[] = [];


  loadingReport: boolean = false;
  reportData: CustomersMessagesReportFullData = null;
  reportDataNonEmpty = () => this.reportData && (this.reportData.received > 0 || this.reportData.sent > 0);

  constructor(
    private readonly router: Router,
    private readonly customerService: CustomerService,
    private readonly messagesService: MessagesService,
    private readonly fb: FormBuilder,
    readonly formsService: FormsService) {
    this.step = this.stepSelectCustomer;
  }


  ngOnInit(): void {
    const self = this;

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      processing: true,
      responsive: true,
      autoWidth: false,
      searchDelay: 250,
      columns: [
        { data: 'id', visible: false, searchable: false },
        { data: 'name' },
        { data: 'lastName' },
        { data: 'username' },
        { data: 'lastName' },
        { data: 'address.country.isoCode' },
        { data: 'contact.phone' }
      ],
      order: [[1, 'asc']],
      ajax: (dataTablesParameters: any, callback) => {
        self.customerService.getListDt(dataTablesParameters)
          .subscribe(resp => {
            self.items = resp.data;

            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          }
          );
      }
    };

    // messages report form
    const checkRangeDates = (group: FormGroup) => {
      const from = group.controls.from.value;
      const to = group.controls.to.value;
      if (!from || !to) {
        return null;
      }
      const dtFrom = new Date(from);
      const dtTo = new Date(to);
      return dtFrom <= dtTo ? null : { invalidRangeDates: true }
    }

    this.form = this.fb.group(
      {
        userIds: this.fb.array([
          this.fb.group({
            id: ['', Validators.required]
          })
        ], [Validators.required]),
        from: ['', [Validators.required]],
        to: ['', Validators.required],
      },
      {
        validators: checkRangeDates
      }
    );
    this.resetDateRange();
  }

  resetDateRange() {
    const beginningOfMonth = (d: any): Date => {
      const date = new Date(d);
      date.setDate(1);
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      return date;
    }

    this.form.patchValue({
      from: formatDate(beginningOfMonth(new Date()), 'yyyy-MM-dd', 'en-us'),
      to: formatDate(new Date(), 'yyyy-MM-dd', 'en-us')
    }
    );
  }

  isSelected(customer: CustomerDto): boolean {
    if (!customer || !customer.id || !this.hasSelectedCustomers) {
      return false;
    }

    const index = this.selectedCustomers.findIndex(c => c.id === customer.id);
    return index >= 0;
  }

  setCustomer(value: CustomerDto) {
    const index = this.selectedCustomers.findIndex(c => c.id === value.id);
    if (index >= 0) {
      this.selectedCustomers.splice(index, 1);
    } else {
      this.selectedCustomers.push(value);
    }

    const ids = this.selectedCustomers.map(c => c.id);
    this.hasSelectedCustomers = ids.length > 0;
    const userIds = this.form.controls["userIds"] as FormArray;
    while (userIds.length) {
      userIds.removeAt(0);
    }
    ids.forEach(id => {
      userIds.push(new FormControl(id));
    });
  }

  goToSelectDate() {
    if (this.form.get('userIds').invalid) {
      return;
    }
    this.step = this.stepSelectDateRange;
  }

  submitForm() {
    if (this.form.invalid || this.loadingReport) {
      return;
    }

    this.loadingReport = true;
    this.reportData = null;
    this.step = this.stepDisplayReport;

    const request = <any>this.form.value as CustomersMessagesReportFullRequest;
    this.messagesService.getCustomersMessagesReportFull(request)
      .subscribe({
        next: res => {
          this.loadingReport = false;
          this.reportData = res;
        },
        error: err => {
          // notity the error
          // console.log('CustomerMessagesReportFull error', err);
          this.loadingReport = false;
        }
      }
      );
  }

  refreshTable(): void {
    this.datatableElement.dtInstance
      .then(
        (dtInstance: DataTables.Api) => {
          dtInstance.draw(true);
        }
      );
  }

  startAgain() {
    this.form.reset();
    this.selectedCustomers.splice(0, this.selectedCustomers.length);
    this.hasSelectedCustomers = false;

    this.resetDateRange();
    this.reportData = null;
    this.step = this.stepSelectCustomer;
    this.datatableElement.dtInstance
      .then(
        (dtInstance: DataTables.Api) => {
          dtInstance.page(0).draw(false);
        }
      );
  }

  computeTotalCost(messages: SentMessageInfo[] | SentMessageInfo[]): number {
    if (!messages || messages.length < 1) {
      return 0;
    }

    return messages.map(i => i.cost).reduce((a, b) => a + b, 0);
  }

}
