import { Router } from '@angular/router';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { NotifierService } from 'angular-notifier';
import { ApiResponseBase, ApiResponseAny, DataTablesResponseAny, DataTablesResponse } from './_shared';

@Injectable(({
  providedIn: 'root'
}) as any)
export class MessagesService {
  private readonly apiUrlBase: string;

  constructor(
    @Inject('BASE_URL') private readonly baseUrl: string,
    private readonly http: HttpClient,
    private readonly router: Router,
    private readonly notifier: NotifierService) {
    this.apiUrlBase = `${this.baseUrl}/api/messages`;
  }

  fetchMessages(request: FetchMessagesRequest): Observable<FetchMessagesData> {
    return this.http.post<ApiResponseBase<FetchMessagesData>>(`${this.apiUrlBase}/fetch`, request)
      .pipe(map(res => {
        if (ApiResponseBase.isSuccess(res)) {
          return res.data;
        }

        this.notifier.notify('error', res.message || 'Unable to fetch messages');
        return null;
      })
      );
  }

  getTops(): Observable<TopMessagesData> {
    return this.http.get<ApiResponseBase<TopMessagesData>>(`${this.apiUrlBase}/top`)
      .pipe(map(res => {
        if (ApiResponseAny.isSuccess(res)) {
          return res.data;
        }

        this.notifier.notify('error', res.message || 'Unable to read group members');
        return null;
      })
      );
  }

  sendMessage(request: SendMessageRequest): Observable<SendMessageResponseData> {
    let formData = new FormData();
    formData.append('text', request.text ? request.text : '');
    formData.append('groupId', request.groupId ? request.groupId : '');
    if (request.to != null) {
      request.to.forEach((to: string) => {
        formData.append('to[]', to ? to : '');
      });
    }
    if (request.files != null) {
      for (let i = 0; i < request.files.length; i++) {
        const file = request.files[i];
        if (file) {
          formData.append('files', file, file.name);
        }
      }
    }

    return this.http.post<ApiResponseBase<SendMessageResponseData>>(`${this.apiUrlBase}/send`, formData)
      .pipe(map(res => {
        if (ApiResponseAny.isSuccess(res)) {
          return res.data;
        }

        this.notifier.notify('error', res.message || 'Unable to send the message');
        return null;
      }
      )
      );
  }

  getMessagesCount(request: MessagesCountRequest): Observable<MessagesCountData> {
    return this.http.post<ApiResponseBase<MessagesCountData>>(`${this.apiUrlBase}/count/customer-and-date`, request)
      .pipe(map(res => {
        if (ApiResponseAny.isSuccess(res)) {
          return res.data;
        }

        this.notifier.notify('error', res.message || 'Unable to retrieve the report data');
        return null;
      })
      );
  }

  getCustomerMessagesReportByCountry(request: CustomerMessagesReportByCountryRequest): Observable<CustomerMessagesReportByCountryData> {
    return this.http.post<ApiResponseBase<CustomerMessagesReportByCountryData>>(`${this.apiUrlBase}/reports/customers/messages/by/countries`, request)
      .pipe(map(res => {
        if (ApiResponseAny.isSuccess(res)) {
          return res.data;
        }

        this.notifier.notify('error', res.message || 'Unable to retrieve the report data');
        return null;
      })
      );
  }

  getCurrentCustomerMessagesReportByCountry(request: MessagesReportByCountryRequest): Observable<CustomerMessagesReportByCountryData> {
    return this.http.post<ApiResponseBase<CustomerMessagesReportByCountryData>>(
      `${this.apiUrlBase}/reports/customer/messages/by/countries`, request)
      .pipe(map(res => {
        if (ApiResponseAny.isSuccess(res)) {
          return res.data;
        }

        this.notifier.notify('error', res.message || 'Unable to retrieve the report data');
        return null;
      })
      );
  }

  getCustomerMessagesReportFull(request: CustomerMessagesReportFullRequest): Observable<CustomerMessagesReportFullData> {
    return this.http.post<ApiResponseBase<CustomerMessagesReportFullData>>(
      `${this.apiUrlBase}/reports/customers/messages/full`, request
    )
      .pipe(map(res => {
        if (ApiResponseAny.isSuccess(res)) {
          return res.data;
        }

        this.notifier.notify('error', res.message || 'Unable to retrieve the report data');
        return null;
      })
      );
  }

  getCustomersMessagesReportFull(request: CustomersMessagesReportFullRequest): Observable<CustomersMessagesReportFullData> {
    return this.http.post<ApiResponseBase<CustomersMessagesReportFullData>>(
      `${this.apiUrlBase}/reports/customers/messages/full-variant2`, request
    )
      .pipe(map(res => {
        if (ApiResponseAny.isSuccess(res)) {
          return res.data;
        }

        this.notifier.notify('error', res.message || 'Unable to retrieve the report data');
        return null;
      })
      );
  }

  getCurrentCustomerMessagesReportFull(request: MessagesReportFullRequest): Observable<CustomerMessagesReportFullData> {
    return this.http.post<ApiResponseBase<CustomerMessagesReportFullData>>(
      `${this.apiUrlBase}/reports/customer/messages/full`, request
    )
      .pipe(map(res => {
        if (ApiResponseAny.isSuccess(res)) {
          return res.data;
        }

        this.notifier.notify('error', res.message || 'Unable to retrieve the report data');
        return null;
      })
      );
  }

  getScheduledMessagesList(datatablesParameters: any): Observable<DataTablesResponse<ScheduledMessageDto>> {
    return this.http.post<DataTablesResponse<ScheduledMessageDto>>(
      `${this.apiUrlBase}/schedule/get-dt`,
      datatablesParameters
    );
  }

  scheduleMessage(request: ScheduleMessageRequest): Observable<ScheduleMessageResponseData> {
    let formData = new FormData();
    formData.append('scheduledDt', request.scheduledDt);
    formData.append('text', request.text ? request.text : '');
    formData.append('groupId', request.groupId ? request.groupId : '');
    if (request.to != null) {
      request.to.forEach((to: string) => {
        formData.append('to[]', to ? to : '');
      });
    }
    if (request.files != null) {
      for (let i = 0; i < request.files.length; i++) {
        const file = request.files[i];
        if (file) {
          formData.append('files', file, file.name);
        }
      }
    }

    return this.http.post<ApiResponseBase<ScheduleMessageResponseData>>(`${this.apiUrlBase}/schedule`, formData)
      .pipe(map(res => {
        if (ApiResponseAny.isSuccess(res)) {
          return res.data;
        }

        this.notifier.notify('error', res.message || 'Unable to schedule the message');
        return null;
      }
      )
      );
  }

  removeScheduled(id: string): Observable<boolean> {
    return this.http.post<ApiResponseAny>(`${this.apiUrlBase}/schedule/${id}/remove`, {})
      .pipe(map(res => {
        if (ApiResponseAny.isSuccess(res)) {
          return true;
        }

        this.notifier.notify('error', res.message || 'Delete scheduled message has failed');
        return false;
      })
      );
  }

  getLastChecks(): Observable<LastCheckResponseData> {
    return this.http.get<ApiResponseBase<LastCheckResponseData>>(`${this.apiUrlBase}/lastchecks`)
      .pipe(map(res => {
        if (ApiResponseAny.isSuccess(res)) {
          return res.data;
        }

        this.notifier.notify('error', res.message || 'Unable to read last checks');
        return null;
      })
      );
  }

  setLastCheck(target: string): Observable<boolean> {
    return this.http.post<ApiResponseAny>(`${this.apiUrlBase}/lastcheck/${target}`, {})
      .pipe(map(res => {
        if (ApiResponseAny.isSuccess(res)) {
          return true;
        }

        this.notifier.notify('error', res.message || 'Set last check has failed');
        return false;
      })
      );
  }

}

export class MessagesReportByCountryRequest {
  from: Date;
  to: Date;

  static parse(input: any): MessagesReportByCountryRequest {
    return input as MessagesReportByCountryRequest;
  }
}

export class CustomerMessagesReportByCountryRequest extends MessagesReportByCountryRequest {
  userId: string;

  static parse(input: any): CustomerMessagesReportByCountryRequest {
    return input as CustomerMessagesReportByCountryRequest;
  }
}

export class MessagesReportFullRequest {
  constructor(public readonly from: Date,
    public readonly to: Date) {
  }
}

export class CustomersMessagesReportFullRequest extends MessagesReportFullRequest {
  constructor(public readonly userIds: string[],
    public readonly from: Date,
    public readonly to: Date) {
    super(from, to);
  }
}

export class CustomerMessagesReportFullRequest extends MessagesReportFullRequest {
  constructor(public readonly userId: string,
    public readonly from: Date,
    public readonly to: Date) {
    super(from, to);
  }
}

export class CustomerMessagesReportFullData {
  sent: number;
  received: number;
  sentMessages: SentMessageInfo[];
  receivedMessages: ReceivedMessageInfo[];
}

export class CustomersMessagesReportFullData extends CustomerMessagesReportFullData {
  // nothing to add for now
}

export class SentMessageInfo {
  to: string;
  cost: number;
  date: Date;
  type: string;
}

export class ReceivedMessageInfo {
  from: string;
  cost: number;
  date: Date;
  type: string;
}

export class CustomerMessagesReportByCountryData {
  countries: Array<CustomerMessagesReportByCountry>;
}

export class CustomerMessagesReportByCountry {
  country: string;
  inbound: SummaryInfo;
  outbound: SummaryInfo;
}

export class SummaryInfo {
  smsPrice: number;
  mmsPrice: number;
  total: number;
  mmsCount: number;
  smsCount: number;
  mmsTotalPrice: number;
  smsTotalPrice: number;
}


export class MessagesCountRequest {
  userId: string;
  from: Date;
  to: Date;

  static parse(input: any): MessagesCountRequest {
    return input as MessagesCountRequest;
  }
}

export class MessagesCountData {
  total: number;
  inbound: MessageCount;
  outbound: MessageCount;
}

export class MessageCount {
  total: number;
  mmsCount: number;
  smsCount: number;
}

export class FetchMessagesRequest {
  channel: string;
  startNumber: number;
  searchQuery: string;

  static parse(input: any): FetchMessagesRequest {
    return input as FetchMessagesRequest;
  }
}

export class FetchMessagesData {
  request: FetchMessagesData;
  total: number;
  count: number;
  pageSize: number;
  nextStartNumber: number;
  messages: Array<MessageItem>;

  static parse(input: any): FetchMessagesData {
    return input as FetchMessagesData;
  }
}

export class MessageItem {
  id: string;
  group: string;
  from: string;
  text: string;
  dt: Date;
  to: Array<TargetStatus>;
  media: Array<string>;
  isInbound: boolean;

  static parse(input: any): MessageItem {
    return input as MessageItem;
  }
}

export class TargetStatus {
  number: string;
  status: string;

  static parse(input: any): TargetStatus {
    return input as TargetStatus;
  }
}

export class TopMessagesData {
  count: number;
  messages: Array<MessageItem>;

  static parse(input: any): TopMessagesData {
    return input as TopMessagesData;
  }
}

export class SendMessageRequest {
  text: string;
  groupId: string;
  to: Array<string>;
  files: Array<File>;

  constructor(text: string, groupId: string, to: Array<string>, files: Array<File>) {
    this.text = text;
    this.groupId = groupId;
    this.to = to;
    this.files = files;
  }
}

export class SendMessageResponseData {
  messageId: string;
  mediaUrls: Array<string>;
  resultResponses: Array<ResultResponse>;
}

export class ResultResponse {
  to: string;
  status: string;
}

export interface ScheduledMessageDto {
  id: string;
  userId?: string;
  scheduledDt: Date;
  groupId?: string;
  to?: string[];
  files?: string[];
  text?: string;
  processedOn?: Date;
  messageId?: string;
  target: string;
}

export interface ScheduleMessageRequest {
  scheduledDt: string;
  text: string;
  groupId?: string;
  to?: Array<string>;
  files?: Array<File>;
}

export interface ScheduleMessageResponseData {
  id: string;
}

export interface LastCheckDto {
  id?: number;
  target: string;
  lastCheckDt: Date;
}

export interface LastCheckResponseData {
  lastChecks?: LastCheckDto[];
  count: number;
}
