import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DataTableDirective } from 'angular-datatables';

import { PersonContactDto } from '../../_models';
import { JwtManagerService } from '../../_helpers';
import { ContactsService, ImportContactsRequest, ImportContactsData, FormsService } from '../../_services';

import { NotifierService } from 'angular-notifier';
import { Router, ActivatedRoute } from '@angular/router';

declare var $: any;

@Component({
  selector: 'customer-contact-list-component',
  templateUrl: './list.component.html',
  styles: []
})
export class CustomerContactListComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  items: PersonContactDto[] = [];
  removing: boolean = false;

  editForm: FormGroup;
  savingForm: boolean = false;

  importingCsv: boolean = false;
  selectedCsv: File = null;
  autoGenNames: boolean = false;
  @ViewChild('csvFile') csvFileElem: ElementRef;

  @ViewChild(DataTableDirective)
  private datatableElement: DataTableDirective;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly notifier: NotifierService,
    private readonly contactsService: ContactsService,
    private readonly jwtManager: JwtManagerService,
    private readonly fb: FormBuilder,
    private readonly formsService: FormsService) {
  }


  ngOnInit(): void {
    var self = this;

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      processing: true,
      responsive: true,
      autoWidth: false,
      searchDelay: 250,
      columns: [
        { data: 'id', visible: false, searchable: false },
        { data: 'name' },
        { data: 'lastName' },
        { data: 'cell1' }
      ],
      order: [[1, 'asc']],
      ajax: (dataTablesParameters: any, callback) => {
        self.contactsService.getContactList(dataTablesParameters)
          .subscribe(resp => {
            self.items = resp.data;

            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          }
          );
      }
    };

    this.editForm = this.fb.group({
      id: [''],
      name: ['', [Validators.required, Validators.minLength(3)]],
      lastName: ['', [Validators.required]],
      cell1: ['', [Validators.pattern('[0-9]+')]]
    }
    );


    // enable selected view
    const selView = this.route.snapshot.paramMap.get('sview');
    switch (selView) {
      case 'add':
        this.addContact();
        break;
      case 'import':
        this.openImportCsv();
        break;
    }
  }

  ngOnDestroy(): void {

  }

  refreshTable(): void {
    this.datatableElement.dtInstance
      .then(
        (dtInstance: DataTables.Api) => {
          dtInstance.draw(true);
        }
      );
  }

  removeContact(item: any, index: number) {
    if (this.removing) {
      return;
    }

    if (!confirm(`Do you want to delete the contact: ${item.name}`)) {
      return;
    }


    this.removing = true;

    this.contactsService.removeContact(item.id)
      .subscribe({
        next: res => {
          if (res) {
            this.refreshTable();
          }
          this.removing = false;
        },
        error: err => {
          this.removing = false;
          // notity the error
          // console.log('[ERR on removeContact]', err);
        }
      }
      );
  }

  isInEditMode(): boolean {
    const c = this.editForm.get('id');
    return c && c.value;
  }

  editItem(item: any) {
    this.editForm.reset();

    this.editForm.patchValue({
      'id': item.id,
      'name': item.name,
      'lastName': item.lastName,
      'cell1': item.cell1
    });

    $('#contactEditorModal').modal('show');
  }

  addContact() {
    this.editItem({});
  }

  submitEditForm() {
    if (!this.editForm.valid) {
      this.formsService.markFormGroupTouched(this.editForm);
      return;
    }

    if (this.savingForm) {
      return;
    }

    const contact = this.editForm.value as PersonContactDto;
    this.contactsService.editContact(contact)
      .subscribe({
        next: res => {
          this.savingForm = false;
          if (res) {
            this.refreshTable();
            $('#contactEditorModal').modal('hide');
          }
        },
        error: err => {
          // notity the error
          // console.log("saveContact error", err);
          this.savingForm = false;
        }
      }
      );
  }

  onSelectedCsvChanged(files: FileList) {
    if (!files || files.length === 0) {
      this.selectedCsv = null;
    }
    this.selectedCsv = files[0];
  }

  openImportCsv() {
    this.csvFileElem.nativeElement.value = null;
    this.importingCsv = false;
    this.selectedCsv = null;
    $('#contactImportModal').modal('show');
  }

  importCsv() {
    if (!this.selectedCsv || this.importingCsv) {
      return;
    }
    this.importingCsv = true;
    const request = new ImportContactsRequest(this.selectedCsv, this.autoGenNames);

    this.contactsService.importContacts(request)
      .subscribe(res => {
        //TODO: display data
        this.importingCsv = false;
        if (res) {
          const text = `Imported: ${res.imported}, Ignored: ${res.ignored}, Invalid: ${res.invalid}`;
          this.notifier.notify('success', text);
          this.refreshTable();
          $('#contactImportModal').modal('hide');
        }
      },
        error => (err) => {
          this.importingCsv = false;
        }
      );
  }
}

