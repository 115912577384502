import { Injectable, Inject } from '@angular/core';
import { Form, FormControl, FormGroup } from '@angular/forms';

@Injectable(({
  providedIn: 'root'
}) as any)
export class FormsService {

  constructor() {}

  /**
   * Marks all controls in a form group as touched
   * @param formGroup - The form group to touch
   */
  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }
}
