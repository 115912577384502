import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';

import {
  UserService, CustomerDto, CustomerService,
  CustomerMessagesReportByCountryRequest, CustomerMessagesReportByCountryData, CustomerMessagesReportByCountry, SummaryInfo, MessagesService,
  FormsService
} from '../../../_services';

import { DataTableDirective } from 'angular-datatables';

declare var $: any;

@Component({
  selector: 'customer-messages-report-1',
  templateUrl: './r1.component.html',
  styleUrls: ['./r1.component.css']
})
export class CustomerMessagesReport implements OnInit {
  private readonly stepSelectDateRange: number = 1;
  private readonly stepDisplayReport: number = 2;

  step: number;

  form: FormGroup;

  loadingReport: boolean = false;
  reportData: CustomerMessagesReportByCountryData = null;
  reportTotals: any = null;

  constructor(
    private readonly router: Router,
    private readonly customerService: CustomerService,
    private readonly messagesService: MessagesService,
    private readonly fb: FormBuilder,
    readonly formsService: FormsService) {
  }


  ngOnInit(): void {
    const self = this;

    // messages report form
    const checkRangeDates = (group: FormGroup) => {
      const from = group.controls.from.value;
      const to = group.controls.to.value;
      if (!from || !to) {
        return null;
      }
      const dtFrom = new Date(from);
      const dtTo = new Date(to);
      return dtFrom <= dtTo ? null : { invalidRangeDates: true }
    }
    this.form = this.fb.group(
      {
        from: ['', [Validators.required]],
        to: ['', Validators.required],
      },
      {
        validators: checkRangeDates
      }
    );
    this.startAgain();
  }

  resetDateRange() {
    const beginningOfMonth = (d: any): Date => {
      let date = new Date(d);
      date.setDate(1);
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      return date;
    }

    this.form.patchValue({
      from: formatDate(beginningOfMonth(new Date()), 'yyyy-MM-dd', 'en-us'),
      to: formatDate(new Date(), 'yyyy-MM-dd', 'en-us')
    }
    );
  }

  goToSelectDate() {
    if (this.form.get('userId').invalid) {
      return;
    }
    this.step = this.stepSelectDateRange;
  }

  submitForm() {
    if (this.form.invalid || this.loadingReport) {
      return;
    }

    this.loadingReport = true;
    this.reportData = null;
    this.step = this.stepDisplayReport;

    let request = CustomerMessagesReportByCountryRequest.parse(this.form.value);
    this.messagesService.getCurrentCustomerMessagesReportByCountry(request)
      .subscribe({
        next: res => {
          this.loadingReport = false;
          this.reportData = res;
          if (res) {
            const totals: any = {
              country: 'Total',
              inbound: {
                smsPrice: 0,
                mmsPrice: 0,
                total: 0,
                smsCount: 0,
                smsTotalPrice: 0,
                mmsCount: 0,
                mmsTotalPrice: 0
              },
              outbound: {
                smsPrice: 0,
                mmsPrice: 0,
                total: 0,
                smsCount: 0,
                smsTotalPrice: 0,
                mmsCount: 0,
                mmsTotalPrice: 0
              }
            };

            res.countries.forEach(c => {
              if (c.inbound) {
                totals.inbound.total += c.inbound.total;
                totals.inbound.smsCount += c.inbound.smsCount;
                totals.inbound.smsTotalPrice += c.inbound.smsTotalPrice;
                totals.inbound.mmsCount += c.inbound.mmsCount;
                totals.inbound.mmsTotalPrice += c.inbound.mmsTotalPrice;
              }

              if (c.outbound) {
                totals.outbound.total += c.outbound.total;
                totals.outbound.smsCount += c.outbound.smsCount;
                totals.outbound.smsTotalPrice += c.outbound.smsTotalPrice;
                totals.outbound.mmsCount += c.outbound.mmsCount;
                totals.outbound.mmsTotalPrice += c.outbound.mmsTotalPrice;
              }
            }
            );

            res.countries.push(totals as CustomerMessagesReportByCountry);

            this.reportTotals = totals;
          }
        },
        error: err => {
          // notity the error
          // console.log('CustomerMessagesReportByCountry error', err);
          this.loadingReport = false;
        }
      }
      );
  }

  startAgain() {
    this.resetDateRange();
    this.reportData = null;
    this.step = this.stepSelectDateRange;
  }

}
