import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService, LoginModel } from '../../_services';
import {
  NgbDate,
  NgbCalendar,
  NgbDatepickerConfig
} from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs';

interface ServerResponse {
  data: {
    deletedCount: number;
  }
}

@Component({
  selector: 'admin-dashboard-component',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  providers: [NgbDatepickerConfig]
})
export class AdminDashboardComponent implements OnInit {

  selectedDate: NgbDate;
  defaultDate: NgbDate;
  placementPopup = 'left';
  oldestMessageDate = new Date();
  messageCount = 0;
  
  constructor(
    private readonly router: Router,
    private calendar: NgbCalendar,
    private http: HttpClient,
    private config: NgbDatepickerConfig,
    private readonly toastr: ToastrService
  ) {
    this.defaultDate = calendar.getPrev(calendar.getToday(), 'm', 2);
    this.getInfoForSelectedDate(this.defaultDate).subscribe((response: any) => {
      this.oldestMessageDate = response.data.oldestMessageDate;
      this.messageCount = response.data.messageCount;
      this.selectedDate = this.getMaxDate(this.oldestMessageDate, this.defaultDate);
      config.minDate = this.getMinDate(this.oldestMessageDate, this.selectedDate);
    });
    config.maxDate = this.calendar.getToday();
    config.outsideDays = 'hidden';
  }

  ngOnInit(): void {
  }

  onSelectedDateChange(): void {
    this.getInfoForSelectedDate(this.selectedDate).subscribe((response: any) => {
      this.oldestMessageDate = new Date(response.data.oldestMessageDate);
      this.messageCount = response.data.messageCount;
      this.config.minDate = this.getMinDate(this.oldestMessageDate, this.selectedDate);
      this.config.outsideDays = 'hidden';
    });
  }

  getMaxDate(date1: Date, date2: NgbDate): NgbDate {
    const jsDate1 = new Date(date1);
    const jsDate2 = new Date(date2.year, date2.month - 1, date2.day);
    return jsDate1 > jsDate2 ? NgbDate.from({ year: jsDate1.getFullYear(), month: jsDate1.getMonth() + 1, day: jsDate1.getDate() }) : date2;
  }

  getMinDate(date1: Date, date2: NgbDate): NgbDate {
    const jsDate1 = new Date(date1);
    const jsDate2 = new Date(date2.year, date2.month - 1, date2.day);
    return jsDate1 < jsDate2 ? NgbDate.from({ year: jsDate1.getFullYear(), month: jsDate1.getMonth() + 1, day: jsDate1.getDate() }) : date2;
  }

  private getInfoForSelectedDate(selectedDate: NgbDate): Observable<any> {
    const date = new Date(selectedDate.year, selectedDate.month - 1, selectedDate.day);
    const formattedDate = new DatePipe('en-US').transform(date, 'yyyy-MM-ddTHH:mm:ss');
    return this.http.post('/api/messages/info', { SelectedDate: formattedDate });
  }
  gotoCustomers() {
    this.router.navigate(['/admin/manage/customers']);
  }

  gotoCustomersAndAddNew() {
    this.router.navigate(['/admin/manage/customers', { sview: 'add' }]);
  }

  gotoServices() {
    this.router.navigate(['/admin/manage/services']);
  }

  gotoServicesAndAddNew() {
    this.router.navigate(['/admin/manage/services', { sview: 'add' }]);
  }

  gotoReports() {
    this.router.navigate(['/admin/reports']);
  }

  gotoCustomerMessagesReport() {
    this.router.navigate(['/admin/reports/customer/messages']);
  }

  deleteOldMessages() {
    const date = new Date(this.selectedDate.year, this.selectedDate.month - 1, this.selectedDate.day);
    const formattedDate = new DatePipe('en-US').transform(date, 'yyyy-MM-ddTHH:mm:ss');
    const confirmed = confirm('This action is irreversible. Are you sure you want to delete all messages before ' + this.selectedDate.year + '-' + this.selectedDate.month + '-' + this.selectedDate.day + '?');
    if (confirmed) {
      this.http.post('/api/messages/clear', { SelectedDate: formattedDate }).subscribe(
        (response: ServerResponse) => {
          const deletedCount = response.data.deletedCount;
          this.toastr.success(deletedCount + ' records successfully deleted');
          this.onSelectedDateChange();
        },
        error => {
          this.toastr.error('Error deleting messages: ' + error.message);
        }
      );
    }
  }
}
