import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse, HttpXsrfTokenExtractor } from '@angular/common/http';

import { Router } from "@angular/router";

import { Observable, BehaviorSubject, throwError, of } from "rxjs";
import { map, catchError, filter, switchMap, take } from 'rxjs/operators';

import { JwtHelperService } from './jwt.helperservice'
import { UserService, LoginUserData } from '../_services';

@Injectable({
  providedIn: 'root',
} as any)
export class JwtInterceptor implements HttpInterceptor {
  // NOTE: some credits to: https://itnext.io/angular-tutorial-implement-refresh-token-with-httpinterceptor-bfa27b966f57
  private refreshTokenInProgress = false;
  // Refresh Token Subject tracks the current token, or is null if no token is currently
  // available (e.g. refresh pending).
  private refreshTokenSubject = new BehaviorSubject<any>(null);

  constructor(
    private readonly jwtHelper: JwtHelperService,
    private readonly userService: UserService,
    private readonly tokenExtractor: HttpXsrfTokenExtractor,
    private readonly router: Router) {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add authorization token
    request = this.addAuthenticationToken(request);

    return next.handle(request)
      .pipe(catchError((error: HttpErrorResponse) => {
        // console.log('error on url', request.url);
        const lastPart = request.url.substr(request.url.lastIndexOf('/') + 1);

        if (lastPart.indexOf('refresh-token') === 0) {
          this.router.navigate(['account/login']);
          return throwError(error);
        }

        if (request.url.indexOf('login') === 0) {
          // console.log("error on login or refresh-token, aborting with error");
          return throwError(error);
        }

        if (error.status !== 401 || !error.headers.has('Token-Expired')) {
          // console.log("no valid status or no token-expired flag, aborting with error");
          return throwError(error);
        }

        if (!this.jwtHelper.getToken() || !this.jwtHelper.getRefreshToken()) {
          // console.log("no token, aborting with error");
          return throwError(error);
        }

        if (this.refreshTokenInProgress) {
          // console.log("refreshTokenInProgress waiting...");
          // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
          // – which means the new token is ready and we can retry the request again
          return this.refreshTokenSubject
            .pipe(
              filter(result => result !== null),
              take(1),
              switchMap((res) => {
                // console.log("token refreshed", res);
                if (!res) {
                  return throwError(error);
                }
                return next.handle(this.addAuthenticationToken(request));
              })
            );
        } else {
          // console.log("refreshTokenInProgress started...");
          this.refreshTokenInProgress = true;

          // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
          this.refreshTokenSubject.next(null);

          return this.userService
            .refreshToken(this.jwtHelper.getToken(), this.jwtHelper.getRefreshToken())
            .pipe(
              switchMap((loginData: LoginUserData) => {
                // When the call to refreshToken completes we reset the refreshTokenInProgress to false
                // for the next time the token needs to be refreshed
                this.refreshTokenInProgress = false;
                this.refreshTokenSubject.next(loginData);

                if (!loginData) {
                  this.router.navigate(['account/login']);
                  this.refreshTokenSubject.next(loginData);
                  return throwError(error);
                }
                return next.handle(this.addAuthenticationToken(request));
              }
              )
            );
        }
      }) as any
      );
  }

  private addAuthenticationToken(request) {
    // Get access token from Local Storage
    const accessToken = this.jwtHelper.getToken();

    // If access token is null this means that user is not logged in
    // And we return the original request
    if (!accessToken) {
      return request;
    }

    // We clone the request, because the original request is immutable
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${accessToken}`
      }
    });
  }
}


/*
 import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';

import { Observable, BehaviorSubject } from "rxjs";

import { JwtHelperService } from './jwt.helperservice'

@Injectable({
  providedIn: 'root',
} as any)
export class JwtInterceptor implements HttpInterceptor {
  constructor(private jwtHelper: JwtHelperService) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    if (this.jwtHelper.isAuthenticated()) {
      let token = this.jwtHelper.getToken();
      if (!this.jwtHelper.isTokenExpired(token)) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`
          }
        });
      }
    }

    return next.handle(request);
  }
}

 */
