import { Router } from '@angular/router';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { NotifierService } from 'angular-notifier';
import { ApiResponseBase, ApiResponseAny} from './_shared';
import { Country } from '../_models';

@Injectable(({
  providedIn: 'root'
}) as any)
export class CountriesService {
  private readonly apiUrlBase: string;

  constructor(
    @Inject('BASE_URL') private readonly baseUrl: string,
    private readonly http: HttpClient,
    private readonly router: Router) {
    this.apiUrlBase = `${this.baseUrl}/api/countries`;
  }

  getList(): Observable<CountryListData> {
    return this.http.get<ApiResponseAny>(`${this.apiUrlBase}`)
      .pipe(map(res => {
          if (ApiResponseAny.isSuccess(res)) {
            return res.data as CountryListData;
          }

          return null;
        })
      );
  }
}

export class CountryListData {
  countries: Country[];
}
