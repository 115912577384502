export class ApiResponseBase<T> {
  status: string;
  message: string;
  code: number;
  data: T;

  public static isSuccess(res: ApiResponseBase<any>): boolean {
    return res && res.status && res.status === 'success';
  }

  public static isError(res: ApiResponseBase<any>): boolean {
    return res && res.status && res.status === 'error';
  }

  public static isFail(res: ApiResponseBase<any>): boolean {
    return res && res.status && res.status === 'fail';
  }
}

export class ApiResponseAny extends ApiResponseBase<any> {

}


export class DataTablesResponse<T> {
  data: T[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}


export class DataTablesResponseAny extends DataTablesResponse<any> {

}
