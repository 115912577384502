import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService, LoginModel } from '../../../_services';

@Component({
  selector: 'customer-reports-index-component',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class CustomerReportsIndexComponent implements OnInit {

  constructor(private readonly  router: Router) {

  }

  ngOnInit(): void {

  }

  gotoCustomerMessagesReport() {
    this.router.navigate(['/customer/reports/messages']);
  }

  gotoCustomerMessagesReportFull() {
    this.router.navigate(['/customer/reports/messages-full']);
  }
}
