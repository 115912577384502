import { Component, OnInit } from '@angular/core';
import { JwtHelperService, JwtManagerService } from '../_helpers';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {

  homeActive: boolean = true;
  contactActive: boolean = false;
  featuresActive: boolean = false;
  isAuthenticated: boolean = false;
  dashboardRoute: string = null;

  constructor(
    private readonly jwtHelper: JwtHelperService,
    private readonly jwtManager: JwtManagerService) {
    this.isAuthenticated = jwtHelper.isAuthenticated();
    if (this.isAuthenticated) {
      let isAdmin, isCustomer;
      try {
        const tokenPayload = this.jwtHelper.decodeToken(this.jwtManager.getToken());
        const roles = tokenPayload.role; // array of string
        const isAdmin = roles && roles.indexOf('Admin') >= 0;
        const isCustomer = roles && roles.indexOf('Customer') >= 0;
        if (isAdmin) {
          this.dashboardRoute = '/admin/dashboard';
        } else if (isCustomer) {
          this.dashboardRoute = '/customer/dashboard';
        }
      } catch (e) {
        // console.log(`error decoding token: ${JSON.stringify(e)}`);
      }
    }
  }

  selectTab(name) {
    //console.log("selectTab", name);
    switch (name) {
      case 'contact':
        this.contactActive = !(this.homeActive = this.featuresActive = false);
        break;
      case 'features':
        this.featuresActive = !(this.homeActive = this.contactActive = false);
        break;
      default:
        this.homeActive = !(this.featuresActive = this.contactActive = false);
        break;
    }
  }

  displayHome() {
    this.selectTab('home');
  }

  displayContact() {
    this.selectTab('contact');
  }

  displayFeatures() {
    this.selectTab('features');
  }
}
